import React from 'react'

import { Redirect,withRouter } from 'react-router-dom'
import { UserContext } from '../../components/context/UserContext';
const Index = () => {
    const ctx = React.useContext(UserContext)
    ctx.setState({
        loggin: false,
        registered: false,
        loading: false,
        error: null,
        errorCode: null,
        role: 0,
        token: null,
        userId: null,
        user: null
    });
    ctx.removeAllLocalStore();
    return (
        <div>
            <Redirect to="" />
        </div>
    )
}
export default withRouter(Index);
