import React from 'react';
import css from './style.module.css'

const index = (props) => {
    return props.isLoading ? <h1>Loading</h1> : <div>
        <div className="form-group">
            <label htmlFor="exampleInputId">Зурагны ID:</label>
            <input disabled={props.isUpdate && 'disabled'} name='id' id='exampleInputId' value={props.banner.id} type="number" onChange={(event) => { props.handleInputChange(event) }} className="form-control" placeholder="Баннерийн код" autoComplete="off" />
            <p className={css.errorMsg}>{props.errorMsg.id.show && props.errorMsg.id.message}</p>
        </div>
        <div className="form-group">
            <label htmlFor="exampleInputId">Эрэмбэ:</label>
            <input name='sort' id='exampleInputId' value={props.banner.sort} type="number" onChange={(event) => { props.handleInputChange(event) }} className="form-control" placeholder="Баннерийн эрэмбэ" autoComplete="off" />
            <p className={css.errorMsg}>{props.errorMsg.id.show && props.errorMsg.sort.message}</p>
        </div>
        <div className="form-group">
            <label htmlFor="exampleInputFile">Барааны зураг:</label>
            {props.isUpdate && props.isUpdate === true && !props.mainimage ?
                <div className="input-group">
                    <img className={css.inputImage} src={props.banner.image || "http://via.placeholder.com/20"} placeholder='Өмнөх зураг' alt='Өмнөх зураг' />
                </div>
                : ''
            }
            <div className="input-group">
                <div className="custom-file">
                    <input type="file" name="image" onChange={(event) => { props.handleInputChange(event) }} id="exampleInputFile" />
                </div>

            </div>
        </div>
    </div>
}

export default index;