import React, { useState } from 'react'
import axios from 'axios'
// import firebase from 'firebase'
import firebase from "firebase/app"
import "firebase/auth"

const auth = firebase.auth()
// import { getAuth, sendPasswordResetEmail } from "firebase/auth";
// const auth = getAuth();
const localLoginKey = 'loggin';
const localRoleKey = 'role';
const localUserKey = 'user';
const localUserIdKey = 'userId';
const initialState = {
    loggin: localStorage.getItem(localLoginKey) || false,
    singin: false,
    registered: false,
    loading: false,
    signUperror: null,
    singUperrorCode: null,
    signInerror: null,
    singInerrorCode: null,
    forgotError: null,
    role: localStorage.getItem(localRoleKey) || 0,
    token: null,
    userId: localStorage.getItem(localUserIdKey) || null,
    user: localStorage.getItem(localUserKey) || null,
    status: null,
}
export const UserContext = React.createContext();
export const UserProvider = (props) => {
    let localLogginStr = localStorage.getItem(localLoginKey) || false;
    let localRoleStr = localStorage.getItem(localRoleKey) || 0;
    const [state, setState] = useState(initialState);
    const signUpUser = (data, phoneNumber) => {
        setState({ ...state, loading: true });
        // console.log("data", data);
        // console.log("data", phoneNumber);
        axios.post("https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyCr-BrZe99Ykvh_tcjZF31m-0VOwmslNzA",
            data).then(result => {
                const user = {
                    id: result.data.localId,
                    email: data.email,
                    phoneNumber,
                    role: 0,
                    status: 0
                }
                firebase.firestore().collection('users').doc(result.data.localId).set(user).then((ref) => {
                    setState({ ...state, loading: false, error: null, errorCode: null, registered: true });
                }).catch(ex => {
                    setState({ ...state, loading: false, signUperror: "сервэртэй холбогдоход алдаа гарлаа" })
                })
            }).catch(err => {

                if (err.message == 'Request failed with status code 400') {
                    err.message = "Бүртгэлтэй email хаяг байна"
                }
                setState({ ...state, loading: false, signUperror: err.message, singUperrorCode: err.code });
            });
    }

    const signInUser = (data) => {
        setState({ ...state, loading: true });
        axios.post("https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyCr-BrZe99Ykvh_tcjZF31m-0VOwmslNzA",
            data).then(result => {
                setState({ ...state, userId: result.data.localId });
                const userREf = firebase.firestore().collection('users').doc(result.data.localId);
                userREf.get().then((doc) => {
                    if (doc.exists) {
                        if (doc.data().status == 1) {
                            // console.log("user:", doc.data());
                            addLocalStore(localUserIdKey, result.data.localId);
                            addLocalStore(localLoginKey, true);
                            addLocalStore(localRoleKey, doc.data().role);
                            addLocalStore(localUserKey, JSON.stringify(doc.data()));
                            // setState({ ...state, user: localStorage.getItem(localUserKey) || null, role: doc.data().role, loggin: true, singin: true });
                            window.location.href = '/';
                        } else {
                            setState({ ...state, loading: false, loggin: false, signInerror: "", status: doc.data().status });
                        }

                    } else {
                        setState({ ...state, loading: false, loggin: false, signInerror: "Бүртгэл олдсонгүй", status: null });
                    }
                }).catch(ex => {
                    setState({ ...state, loggin: false, loading: false, signInerror: "сервэртэй холбогдоход алдаа гарлаа", status: null })
                })
            }).catch(err => {
                setState({ ...state, loading: false, signInerror: "Нууц үг нэвтрэх нэр буруу байна", singInerrorCode: err.code, status: null });
            });

    }
    const resetPassword = (email) => {
        // console.log("email hayag", email)
        auth.sendPasswordResetEmail(email)
            .then(function () {
                
                setState({ ...state, forgotError: "" })
                alert('Email хаягаа шалгана уу')
            }).catch(function (e) {
                console.log("err", e.code);
                if (e.code == "auth/user-not-found") {
                    setState({ ...state, forgotError: "Ийм бүртгэлтэй email байхгүй байна" })
                }
            })
    }
    const addLocalStore = (id, value) => {
        localLogginStr = true;
        localStorage.setItem(id, value);
    }

    const readFromLocalStore = (id) => {
        return id;
    }
    const removeAllLocalStore = () => {
        localStorage.removeItem(localLoginKey);
        localStorage.removeItem(localRoleKey);
        localStorage.removeItem(localUserKey);
        localStorage.removeItem(localUserIdKey);
    }
    return (
        <UserContext.Provider value={{ state, signUpUser, signInUser, setState, removeAllLocalStore, resetPassword }}>{props.children}</UserContext.Provider>
    )
}
