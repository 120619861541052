import React, { Component } from "react";
import firebase from "firebase/app";
import "firebase/firestore";

class CourseContent extends Component {
  state = {
    course: {
      id: "",
      title: "",
      embedContent: "", // Replace description and order with embedContent
      createdAt: new Date(),
    },
    courses: [],
    loading: false,
    error: null,
    isEditing: false,
  };

  componentDidMount() {
    this.fetchCourses();
  }

  //create
  createCourse = async () => {
    const { course } = this.state;
    if (!course.id) {
      this.setState({ error: "Course ID is required" });
      return;
    }

    this.setState({ loading: true });

    try {
      await firebase
        .firestore()
        .collection("courses")
        .doc(course.id.toString())
        .set({
          ...course,
          createdAt: new Date(),
        });

      this.setState({
        course: {
          id: "",
          title: "",
          embedContent: "",
          createdAt: new Date(),
        },
        loading: false,
        error: null,
      });
      this.fetchCourses();
    } catch (error) {
      this.setState({ error: error.message, loading: false });
    }
  };

  // READ
  fetchCourses = async () => {
    this.setState({ loading: true });

    try {
      const snapshot = await firebase
        .firestore()
        .collection("courses")
        .orderBy("createdAt", "desc")
        .get();

      const courses = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      this.setState({ courses, loading: false });
    } catch (error) {
      this.setState({ error: error.message, loading: false });
    }
  };

  // UPDATE

  updateCourse = async () => {
    const { course } = this.state;
    this.setState({ loading: true });

    try {
      await firebase
        .firestore()
        .collection("courses")
        .doc(course.id)
        .update({
          ...course,
          updatedAt: new Date(),
        });

      this.setState({
        course: {
          id: "",
          title: "",
          embedContent: "",
        },
        isEditing: false,
        loading: false,
      });
      this.fetchCourses();
    } catch (error) {
      this.setState({ error: error.message, loading: false });
    }
  };

  // DELETE
  deleteCourse = async (courseId) => {
    if (!window.confirm("Are you sure you want to delete this course?")) return;

    this.setState({ loading: true });

    try {
      await firebase.firestore().collection("courses").doc(courseId).delete();

      this.fetchCourses();
    } catch (error) {
      this.setState({ error: error.message, loading: false });
    }
  };

  // Helper Methods
  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      course: {
        ...this.state.course,
        [name]: value,
      },
    });
  };

  setEditCourse = (course) => {
    this.setState({
      course,
      isEditing: true,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.isEditing) {
      this.updateCourse();
    } else {
      this.createCourse();
    }
  };

  render() {
    const { course, courses, loading, error, isEditing } = this.state;

    return (
      <div className="container mx-auto p-4">
        {error && <div className="text-red-500 mb-4">{error}</div>}

        <form onSubmit={this.handleSubmit} className="mb-8 flex w-full !gap-4">
          <input
            type="text"
            name="id"
            value={course.id}
            onChange={this.handleInputChange}
            placeholder="Course ID (required)"
            className="block w-full mb-4 p-2 border rounded"
            required
            disabled={isEditing}
          />
          <input
            type="text"
            name="title"
            value={course.title}
            onChange={this.handleInputChange}
            placeholder="Course Title"
            className="block w-full mb-4 p-2 border rounded"
          />
          <textarea
            name="embedContent"
            value={course.embedContent}
            onChange={this.handleInputChange}
            placeholder="Embed Content (iframe, embed code, etc.)"
            className="block w-full border rounded font-mono"
            rows={4}
          />
          <button
            type="submit"
            disabled={loading}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            {loading
              ? "Processing..."
              : isEditing
              ? "Update Course"
              : "Add Course"}
          </button>
        </form>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {courses.map((course) => (
            <div key={course.id} className="border p-4 rounded">
              <h3 className="font-bold">{course.title}</h3>
              <div
                className="mt-4 embed-container"
                dangerouslySetInnerHTML={{ __html: course.embedContent }}
              />
              <div className="mt-4 space-x-2">
                <button
                  onClick={() => this.setEditCourse(course)}
                  className="bg-yellow-500 text-white px-4 py-2 rounded"
                >
                  Edit
                </button>
                <button
                  onClick={() => this.deleteCourse(course.id)}
                  className="bg-red-500 text-white px-4 py-2 rounded"
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default CourseContent;
