import React, { Component } from 'react'
import css from './style.module.css';

const ProductDetail = (props) => {
    const modalToggle = () => {
        props.setShow(false);
    }
    return (
        <div className={css.ModalBackdrop} >
            <div className={css.ModalBody}>
                <button onClick={modalToggle} className={css.CloseSpan}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="10.003" height={10} viewBox="0 0 10.003 10" style={{ width: 11, height: 11 }}><path data-name="_ionicons_svg_ios-close (5)" d="M166.686,165.55l3.573-3.573a.837.837,0,0,0-1.184-1.184l-3.573,3.573-3.573-3.573a.837.837,0,1,0-1.184,1.184l3.573,3.573-3.573,3.573a.837.837,0,0,0,1.184,1.184l3.573-3.573,3.573,3.573a.837.837,0,0,0,1.184-1.184Z" transform="translate(-160.5 -160.55)" fill="currentColor" /></svg>
                </button>
                <div className={css.ModalTextArea}>
                    <div className={css.bEucZD}>
                        <div className={css.dncRqP+' '+css.marginvh}>
                            <div className={css.dHQHGm}>
                                <div className={css.dncRqP}>
                                    <img src={props.product.image} className={css.dHQHGmImg}/>
                                </div>
                            </div>
                            <div className={css.SPnrf}>
                                <div className={css.ixsiGC}>
                                    <h1 value={props.product.id} className={css.fHUdc}>Код:{props.product.id}</h1>
                                    <div className={css.lfdRJ}>
                                        <h1 value={props.product.name} className={css.fHUdcH}>{props.product.name}</h1>
                                        <h1 className={css.fHUdcH1}>{props.product.point} оноо</h1>
                                    </div>
                                    <div className={css.scroll}>
                                        <div className={css.flexcolumn} style={{gap:'10px'}}>
                                            <div className={css.descritem}>
                                                <div className={css.descritemhead} data-toggle="collapse" data-target="#demo3">Дэлгэрэнгүй :</div>
                                                <div id="demo3" className={"collapse show "+ css.descritemdetail}>
                                                    <p className={css.kOEjVM}>{props.product.description}</p>
                                                </div>
                                            </div>
                                            {/* <div className={css.descritem}>
                                                <div className={css.descritemhead} data-toggle="collapse" data-target="#demo">Онцлог:</div>
                                                <div id="demo" className={"collapse "+ css.descritemdetail}>
                                                    <p className={css.kOEjVM}>{props.product.specialDescription}</p>
                                                </div>
                                            </div>
                                            <div className={css.descritem}>
                                                <div className={css.descritemhead} data-toggle="collapse" data-target="#demo1">Ашиг тус:</div>
                                                <div id="demo1" className={"collapse "+ css.descritemdetail}>
                                                    <p className={css.kOEjVM}>{props.product.goodDescription}</p>
                                                </div>
                                            </div> */}
                                            {/* <div className={css.descritem}>
                                                <div className={css.descritemhead} data-toggle="collapse" data-target="#demo2">Хэрэглэх заавар :</div>
                                                <div id="demo2" className={"collapse "+ css.descritemdetail}>
                                                    <p className={css.kOEjVM}>{props.product.bodyDescription}</p>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className={css.kKdNbW}>
                                        <div className={css.wGaXT}>
                                            <div className={css.CIPBX}>15%</div>
                                            {/* <div className={css.CIPBX}>23%</div> */}
                                            {/* <div className={css.CIPBX}>100%</div> */}
                                        </div>
                                        <div className={css.wGaXT}>
                                            <div className={css.CIPBX1}>{props.product.price}₮</div>
                                            {/* <div className={css.CIPBX1}>{props.product.price1}₮</div> */}
                                            {/* <div className={css.CIPBX1}>{props.product.price2}₮</div> */}
                                        </div>
                                        {/* <div className={css.gurUMl}>
                                            <button className={css.hAARBe}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14.4px" height="12px" viewBox="0 0 14.4 12" fill="currentColor" className={css.vfnVS}><g data-name="Group 120" transform="translate(-288 -413.89)"><path data-name="Path 154" fill="currentColor" d="M298.7,418.289l-2.906-4.148a.835.835,0,0,0-.528-.251.607.607,0,0,0-.529.251l-2.905,4.148h-3.17a.609.609,0,0,0-.661.625v.191l1.651,5.84a1.336,1.336,0,0,0,1.255.945h8.588a1.261,1.261,0,0,0,1.254-.945l1.651-5.84v-.191a.609.609,0,0,0-.661-.625Zm-5.419,0,1.984-2.767,1.98,2.767Zm1.984,5.024a1.258,1.258,0,1,1,1.319-1.258,1.3,1.3,0,0,1-1.319,1.258Zm0,0" /></g></svg>
                                                <span className={css.buttonSpan}>Add to cart</span>
                                            </button>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ProductDetail