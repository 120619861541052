import React from 'react'
import { withRouter } from 'react-router-dom'

import css from './style.module.css'
const OrderReceived = (props) =>{
    
    const order = props.location.state;
    // console.log('test:',order);
    // css 2 classs
    let bHcUtGAndTitle = [css.bHcUtG, css.title].join(' ');
    return (
        <div className={css.eTHeCj}>
            <div className={css.fZqxml}>
                <a className={css.homebtn} href='/YourOrder'>Миний захиалгууд</a>
                <div className={css.eIRhpR}>
                    <h2 className={css.QVyIh}>Захиалга хүлээн авлаа</h2>
                    <p className={css.bvSdvR}>Баярлалаа. Таны захиалгыг хүлээн авлаа</p>
                    <div className={css.eIHWxM}>
                        <div className={css.gSskSI}>
                            <p className={bHcUtGAndTitle}>Захиалгын дугаар</p>
                            <p className={css.bvSdvR}>{order.orderid}</p>
                        </div>
                        <div className={css.gSskSI}>
                            <p className={bHcUtGAndTitle}>Захиалга хийсэн өдөр</p>
                            <p className={css.bvSdvR}>{order.orderdate}</p>
                        </div>
                        <div className={css.gSskSI}>
                            <p className={bHcUtGAndTitle}>Нийт үнэ</p>
                            <p className={css.bvSdvR}>(15%)&nbsp;&nbsp;&nbsp;&nbsp;{order.totalprice}₮</p>
                            {/* <p className={css.bvSdvR}>(23%)&nbsp;&nbsp;&nbsp;&nbsp;{order.totalprice1}₮</p> */}
                            {/* <p className={css.bvSdvR}>(100%)&nbsp;{order.totalprice2}₮</p> */}
                        </div>
                    </div>
                </div>
                <div className={css.dEojFW}>
                    <h2 className={css.QVyIh}>Захиалгын дэлгэрэнгүй</h2>
                    <div className={css.hXfWwV}>
                        <div className={css.jHaRgo}>
                            <p className={css.bHcUtG}>Нийт Зүйл</p>
                        </div>
                        <div className={css.gADUYd}>
                            <p className={css.bvSdvR}>{order.itemlen} зүйл</p>
                        </div>
                    </div>
                    <div className={css.hXfWwV}>
                        <div className={css.jHaRgo}>
                            <p className={css.bHcUtG}>Захиалга Хүлээн Авах Утас</p>
                        </div>
                        <div className={css.gADUYd}>
                            <p className={css.bvSdvR}>{order.deliverynumber}</p>
                        </div>
                    </div>
                    <div className={css.hXfWwV}>
                        <div className={css.jHaRgo}>
                            <p className={css.bHcUtG}>Захиалга Хүлээн Авах Хаяг</p>
                        </div>
                        <div className={css.gADUYd}>
                            <p className={css.bvSdvR}>{order.deliveryaddress}</p>
                        </div>
                    </div>
                </div>
                <div className={css.footerwarn}>
                    <h2 className={css.QVyIh}>Захиалгын баталгаажуулалт</h2>
                    <p className={css.bvSdvR}>Та Хаан банк - 5030155180 /Бямбасүх/ дансанд төлбөрөө төлнө үү. Гүйлгээний утга дээр захиалгын дугаар болон утасны дугаар заавал бичнэ үү. Төлбөрөө төлсний дараа 88001385 дугаарт холбогдон захиалгаа баталгаажуулна уу . Баярлалаа &#128515;</p>
                </div>
            </div>
        </div>
    );
}
export default withRouter(OrderReceived);