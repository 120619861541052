import React from 'react'
import OrderDetailsDataTable from '../OrderDetailsDataTable';

import { format } from "date-fns";
import './style.css'
export default class OrderControl extends React.Component {
    constructor(props) {
        super(props);
        this.setDate = this.setDate.bind(this);
        this.state = {
            date: null
        }
    }
    componentDidMount() {
        this.setDate();
    }
    async setDate() {
        this.setState({
            date: format(new Date(), "yyyy-MM-dd HH:mm")
        });

    }
    printElement(elem) {
        var domClone = elem.cloneNode(true);
        var $printSection = document.getElementById("printSection");
        if (!$printSection) {
            var $printSection = document.createElement("div");
            $printSection.id = "printSection";
            document.body.appendChild($printSection);
        }

        $printSection.innerHTML = "";
        $printSection.appendChild(domClone);
        window.print();
    }
    captureElement(elem) {
        var domClone = elem.cloneNode(true);
        var $printSection = document.getElementById("printSection");
        if (!$printSection) {
            var $printSection = document.createElement("div");
            $printSection.id = "printSection";
            document.body.appendChild($printSection);
        }

        $printSection.innerHTML = "";
        $printSection.appendChild(domClone);
        
    }
    render() {
        // console.log("order control render");
        return (
            <div className="modal fade " id="modal-default" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-lg"   >
                    <div className="modal-content" >
                        <div className="modal-header">
                            <h4 className="modal-title">Захиалгын дэлгэрэнгүй мэдээлэл</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body" id="modalBody" >
                            <div className="card card-primary" >
                                <div className="card-body" style={{ "textAlign": "left" }}>
                                    <OrderDetailsDataTable date={this.state.date} order={this.props.order}></OrderDetailsDataTable>

                                    <div className="modal-footer justify-content-between">
                                        <button id="closeModal" type="button" className="btn btn-danger" data-dismiss="modal">Хаах</button>
                                        <button id="printModal" className="btn btn-success" onClick={() => {
                                            this.setDate().then(
                                                this.printElement(document.getElementById("modalBody"))
                                            );
                                        }} >Хэвлэх</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        )
    }
}
