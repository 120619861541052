import React, { Component } from 'react'
import css from './style.module.css'

export default class index extends Component {
    render() {
        return (
            <div className="modal fade bd-example-modal-sm" id="modal-number">
                <button type="button" className={css.reuseModalCloseBtn} data-dismiss="modal" aria-label="Close">
                    <i className='fa fa-times'></i>
                </button>
                <div className="modal-dialog modal-sm modal-dialog-centered">
                    <div className="modal-content">
                        <div className={css.innerRndComponent}>
                            <form action='#'>
                                <div className={css.bHlADF}>Шинэ дугаар нэмэх</div>
                                <div className={css.deMkW}>
                                    <div>
                                        <input type='hidden' name='name' value={this.props.data.name}></input>
                                        <input name='val' value={this.props.data.val} placeholder="Утасны дугаар" type='number' onChange={(event)=>this.props.handleInputChange(event)} className={css.iapefQ}></input>
                                    </div>
                                </div>
                                <button type='button' className={css.dwDYsr} data-dismiss="modal" aria-label="Close" disabled={this.props.formValid ? "" : "disabled"}  onClick={() => {this.props.addfunc()}}>{this.props.data.isupdate?'Дугаар засварлах':'Дугаар хадгалах'}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
