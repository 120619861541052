import React, { Component } from 'react'
import firebase from '../../../../firebase'

const Index = () => {
    const [prodLen, setProdLen] = React.useState(0);
    const [orderLen, setOrderLen] = React.useState(0);
    const [orderLen0, setOrderLen0] = React.useState();
    const [userLen, setUserLen] = React.useState();
    React.useEffect(()=>{
        firebase.firestore().collection('users').where('status', '==', '0')
        .onSnapshot((collections) => {
            const users = collections.docs.map((product) => product.data());
            setUserLen(users.length);
        });

        firebase.firestore().collection('product')
        .onSnapshot((collections) => {
            const products = collections.docs.map((product) => product.data());
            setProdLen(products.length);
        });
        firebase.firestore().collection('order')
        .onSnapshot((collections) => {
            let orders = collections.docs.map((product) => product.data());
            let orderlen0 = 0;
            for(let i = 0; i<orders.length; i++){
                if(orders[i].orderstatus == 0){
                    orderlen0++;
                }
            }
            setOrderLen0(orderlen0);
            setOrderLen(orders.length);
        })
    },[]);
    return (
        <div className="row">
            <div className="col-lg-3 col-6">
                <div className="small-box bg-info">
                    <div className="inner">
                        <h3>{orderLen0}</h3>
                        <p>Хүлээн авсан захиалга</p>
                    </div>
                    <div className="icon">
                        <i className="ion ion-bag" />
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-6">
                <div className="small-box bg-success">
                    <div className="inner">
                        <h3>{orderLen}</h3>
                        <p>Нийт захиалга</p>
                    </div>
                    <div className="icon">
                        <i className="ion ion-stats-bars" />
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-6">
                <div className="small-box bg-warning">
                    <div className="inner">
                        <h3>{userLen}</h3>
                        <p>Бүртгүүлэх хүсэлт</p>
                    </div>
                    <div className="icon">
                        <i className="ion ion-person-add" />
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-6">
                <div className="small-box bg-danger">
                    <div className="inner">
                        <h3>{prodLen}</h3>
                        <p>Бүртгэдсэн бараа</p>
                    </div>
                    <div className="icon">
                        <i className="ion ion-pie-graph" />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Index 
