import React,{useState, useEffect, useRef} from 'react'
import firebase from 'firebase'

const Sitedata = () => {

    const [siteterm,setsiteterm] = React.useState('');
    const term = useRef();
    const termviewOn = async () => {
        const sitedata = await firebase.firestore().collection("sitedata").doc("sitedata").get().then((doc) => doc.data());
        setsiteterm(sitedata.siteterm);
      }

      const set = async () => {
        await firebase.firestore().collection("sitedata").doc("sitedata").set({siteterm:term.current.value})
        alert("Амжилттай");
      }

    useEffect(()=>{
        termviewOn()
    },[])
  return (
    
        
            <div className="content-wrapper">
   
    <section className="content">
        <div className="container-fluid">
       
        <div style={{display:'flex',flexDirection:'column',alignItems:'center',padding:'30px',gap:'20px'}}>
                <label>Үйлчилгээний нөхцөл</label>
            <textarea style={{width:'90%',height:'300px',padding:'20px'}} defaultValue={siteterm} ref={term}></textarea>
            <button className='btn btn-success' onClick={()=>set()}>Хадгалах</button>
            </div>
           
        </div>
    </section>
</div>
    
  )
}

export default Sitedata