import React, {useContext} from 'react';
import {BasketContext} from '../../../context/BasketContext'
import {BasketItem} from '../BasketItem'
import { NavLink } from 'react-router-dom'
import { ExchangeContext } from '../../../context/ExchangeContext'

import css from './style.module.css'
export const BasketDetail = () => {
    let ctx = useContext(BasketContext);
    // console.log('ctx:', ctx)
    let ischeckout = true;
    ctx.basket.map((item)=>{
        if(item.quantity<item.qty){
            ischeckout=false;
        }
    })
    // const eTotalPrice = ctx.exchange!=-1?(ctx.totalprice*ctx.exchange).toFixed():'Боломжгүй';
    // const eTotalPrice1 = ctx.exchange!=-1?(ctx.totalprice1*ctx.exchange).toFixed():'Боломжгүй';
    // const eTotalPrice2 = ctx.exchange!=-1?(ctx.totalprice2*ctx.exchange).toFixed():'Боломжгүй';
    return (
        <div>
            <div className={css.lamVFu}>
                <div className={css.jKfBPg}>
                    <div className={css.gkbJjA}>
                        <div className={css.fhKndd}>
                            <i className="fa fa-shopping-basket"></i>
                            <span>{ctx.basket.length}&nbsp;Төрөл</span>
                        </div>
                        <div className={css.fhKndd}>
                            <div className={ischeckout ? css.black : css.red}>{ctx.totalpoint}оноо</div>
                        </div>
                        <button onClick={() => { ctx.setShow(false) }} className={css.kWBUWu}>
                            <i className="fa fa-times"></i>
                        </button>
                    </div>
                    <div className={css.cartScrollbar}>
                        <div className={css.ospadding}>
                            <div className={css.iKtYkh}>
                                {
                                    ctx.basket.map((item)=>(
                                        <BasketItem key={item.id} item={item}></BasketItem>
                                    ))
                                }
                            </div>
                        </div>
                        {/* <div className={css.osscrollbarvertical}>
                            <div className={css.osscrollbartrackoff}>
                                <div className={css.osscrollbarhandle}></div>
                            </div>
                        </div> */}
                    </div>
                    <div className={css.bhHvDf} >
                        {
                        // ischeckout?
                        <NavLink to='/savetobasket' className={css.dQOcYU} style={{backgroundColor:'#FFB369'}}>
                            <div className={css.kVQlLW}>
                                Сагсруу хадгалах
                            </div>
                            <div className={css.flexcolumn+' '+css.iDSbKK}>
                                <span className={css.iDSbKKspan}>{ctx.totalprice}₮/<span className={css.productpercent}>15%</span>/</span>
                                {/* <span className={css.iDSbKKspan}>{ctx.totalprice1}₮/<span className={css.productpercent}>23%</span>/</span> */}
                            </div>
                        </NavLink>
                        // :
                        // <div onClick={()=>{alert('Агуулах дахь барааны үлдэгдэл хүрэлцэхгүй байна')}} className={css.dQOcYU}>
                        //     <a className={css.kVQlLW}>
                        //         Захиалах
                        //     </a>
                        //     <div className={css.flexcolumn+' '+css.iDSbKK}>
                        //         <span className={css.iDSbKKspan}>{ctx.totalprice}₮/<span className={css.productpercent}>15%</span>/</span>
                        //         {/* <span className={css.iDSbKKspan}>{ctx.totalprice1}₮/<span className={css.productpercent}>23%</span>/</span> */}
                        //     </div>
                        // </div>
                        }
                    </div>
                    <div className={css.bhHvDf}>
                        {
                        // ischeckout?
                        <NavLink to='/checkout' className={css.dQOcYU}>
                            <div className={css.kVQlLW}>
                                Захиалах
                            </div>
                            <div className={css.flexcolumn+' '+css.iDSbKK}>
                                <span className={css.iDSbKKspan}>{ctx.totalprice}₮/<span className={css.productpercent}>15%</span>/</span>
                                {/* <span className={css.iDSbKKspan}>{ctx.totalprice1}₮/<span className={css.productpercent}>23%</span>/</span> */}
                            </div>
                        </NavLink>
                        // :
                        // <div onClick={()=>{alert('Агуулах дахь барааны үлдэгдэл хүрэлцэхгүй байна')}} className={css.dQOcYU}>
                        //     <a className={css.kVQlLW}>
                        //         Захиалах
                        //     </a>
                        //     <div className={css.flexcolumn+' '+css.iDSbKK}>
                        //         <span className={css.iDSbKKspan}>{ctx.totalprice}₮/<span className={css.productpercent}>15%</span>/</span>
                        //         {/* <span className={css.iDSbKKspan}>{ctx.totalprice1}₮/<span className={css.productpercent}>23%</span>/</span> */}
                        //     </div>
                        // </div>
                        }
                    </div>
                </div>
            </div>

        </div>
    )
}
