import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import CheckoutDetailPop from "../../Component/CheckoutDetailPop";
import { CheckoutDetail } from "../../Component/CheckoutDetail";
import CheckoutAddAddress from "../../Component/CheckoutAddAddress";
import CheckoutAddNumber from "../../Component/CheckoutAddNumber";
import { validation } from "../../../../util/Validator";
import { BasketContext } from "../../../context/BasketContext";
import { UserContext } from "../../../context/UserContext";
import firebase from "../../../../firebase";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import css from "./style.module.css";
import { stat } from "fs";
const Index = (props) => {
  const ctx = React.useContext(BasketContext);
  const ctxUser = React.useContext(UserContext);
  const objuser = JSON.parse(ctxUser.state.user);
  // Захиалга хийсний дараа баазад хадгалагдах бүтээгдэхүүн
  var updateproducts = [];
  // Захиалга хийхийн өмнөх захиалага дахт бүтээгдэхүүн
  var oldproducts = [];
  // console.log('ctxUser:',ctx.exchange);
  let defaultData = {
    data: {
      name: "",
      val: "",
      isupdate: false,
      key: "",
    },
    errorMsg: {
      name: { show: false },
      val: { show: false },
    },
  };
  // state
  const [data, setData] = React.useState({ ...defaultData.data });
  const [errorMsg, setErrorMsg] = React.useState({ ...defaultData.errorMsg });
  const [seladdress, setSelAddress] = React.useState(0);
  const [selnumber, setSelNumber] = React.useState(0);
  const [formValid, setFormValid] = React.useState(false);
  const [order, setOrder] = React.useState(null);
  const [user, setUser] = React.useState(null);
  React.useEffect(() => {
    // user
    firebase
      .firestore()
      .collection("users")
      .where("email", "==", objuser.email)
      .get()
      .then((collections) => {
        const users = collections.docs.map((product) => product.data());
        if (users.length > 0) {
          setUser(users[0]);
          // console.log('test ctx:',ctx.exchange);
          setOrder({
            orderstatus: 0,
            userno: objuser.phoneNumber,
            email: users[0].email,
            rate: ctx.exchange,
            totalprice: ctx.totalprice,
            totalprice1: ctx.totalprice1,
            // totalprice2:ctx.totalprice2,
            totalpoint: ctx.totalpoint,
            deliveryaddress:
              users[0] && users[0].myaddresses
                ? users[0].myaddresses[0].val
                : null,
            deliverynumber:
              users[0] && users[0].mynumbers ? users[0].mynumbers[0].val : null,
            orderdetail: [
              ...ctx.basket.map((item) => ({
                id: item.id,
                name: item.name,
                quantity: item.qty,
                price: item.price,
                price1: item.price1,
                point: item.point,
              })),
            ],
          });
        }
        // console.log('users: ',users);
      });
    // order
  }, [ctx.basket]);
  // Хүргэх хаяг болон захиалгын утас хэсгийг солих
  const onChangeRadioValue = (event) => {
    let id = event.target.id.substr(event.target.id.length - 1, 1);
    let name = event.target.id.substr(0, event.target.id.length - 1);
    switch (name) {
      case "address":
        setSelAddress(id);
        setOrderData(user.myaddresses[id].val, null);
        break;
      case "number":
        setSelNumber(id);
        setOrderData(null, user.mynumbers[id].val);
        break;
    }
  };
  // шинэ хаяг нэмэх
  const addAddress = () => {
    setUser((curstate) => {
      const myaddresses = data.isupdate
        ? curstate.myaddresses.map((item, j) => {
            if (j === data.key) {
              return { name: data.name, val: data.val };
            } else {
              return item;
            }
          })
        : user.myaddresses
        ? [...user.myaddresses, { name: data.name, val: data.val }]
        : [{ name: data.name, val: data.val }];
      const sel = data.isupdate ? data.key : myaddresses.length - 1;
      setSelAddress(sel);
      setOrderData(data.val, null);
      return {
        ...user,
        myaddresses: myaddresses,
      };
    });
    setData(defaultData.data);
  };
  // шинэ дугаар нэмэх
  const addNumber = () => {
    setUser((curstate) => {
      // console.log("datas:",user.mynumbers)
      const mynumbers = data.isupdate
        ? curstate.mynumbers.map((item, j) => {
            if (j === data.key) {
              return { name: data.name, val: data.val };
            } else {
              return item;
            }
          })
        : user.mynumbers
        ? [...user.mynumbers, { name: data.name, val: data.val }]
        : [{ name: data.name, val: data.val }];
      const sel = data.isupdate ? data.key : mynumbers.length - 1;
      setSelNumber(sel);
      setOrderData(null, data.val);
      return {
        ...user,
        mynumbers: mynumbers,
      };
    });
    setData(defaultData.data);
  };
  // захиалгын мэдээлэл бөглөх
  const setOrderData = (selAdd, selNum) => {
    // console.log('order1: ',order);
    setOrder({
      ...order,
      deliveryaddress:
        selAdd == null ? order.deliveryaddress : selAdd == -1 ? null : selAdd,
      deliverynumber:
        selNum == null ? order.deliverynumber : selNum == -1 ? null : selNum,
    });
  };
  const delAddress = (key) => {
    user.myaddresses.splice(key, 1);
    setSelAddress(user.myaddresses.length - 1);
    setOrderData(
      user.myaddresses.length - 1 < 0
        ? -1
        : user.myaddresses[user.myaddresses.length - 1].val,
      null
    );
    setUser({ ...user });
  };
  const delNumber = (key) => {
    user.mynumbers.splice(key, 1);
    setSelNumber(user.mynumbers.length - 1);
    setOrderData(
      null,
      user.mynumbers.length - 1 < 0
        ? -1
        : user.mynumbers[user.mynumbers.length - 1].val
    );
    setUser({ ...user });
  };
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const validate = validation(value);
    let formValid = false;
    if (!validate.show) {
      let istemvavlid = true;
      Object.values(data).map((el, index) => {
        if (
          ![2, 3].includes(index) &&
          el.length == 0 &&
          Object.keys(errorMsg)[index] != [name]
        ) {
          istemvavlid = false;
          return;
        }
      });
      if (istemvavlid) {
        formValid = true;
      }
    }
    setErrorMsg({
      ...errorMsg,
      [name]: validate,
    });
    setData({
      ...data,
      [name]: value,
    });
    setFormValid(formValid);
  };
  const setAddData = (opt) => {
    setErrorMsg({ ...defaultData.errorMsg });
    setData({ ...defaultData.data, name: opt == "number" ? "Гар Утас" : "" });
    setFormValid(false);
  };
  const setUpdateData = (data, key) => {
    setData({ ...data, isupdate: true, key: key });
    setFormValid(true);
  };
  const submitForm = () => {
    if (order && order.orderdetail.length !== 0) {
      if (checkOrder()) {
        confirmAlert({
          title: "Сагсруу хадгалах",
          message: "Та үүнийг хийхдээ итгэлтэй байна уу?",
          buttons: [
            {
              label: "Тийм",
              onClick: () => {
                ctx.setIsLoading(true);
                setOrderToBase();
                
              },
            },
            {
              label: "Үгүй",
              onClick: () => {},
            },
          ],
        });
      }
    } else {
      console.log("Бүтээгдэхүүн олдсонгүй");
      alert("Бүтээгдэхүүн олдсонгүй");
    }
  };
  // 1-р алхам захиалгын мэдээлэл шалгах
  const checkOrder = () => {
    // console.log('order.userno:',order.userno);
    if (!order.userno) {
      alert("Хэрэглэгчийн мэдээлэл олдсонгүй!");
      return false;
    }
    else if (!order.totalprice) {
      alert("Нийт үнэ тооцоологдоогүй байна!");
      return false;
    } else if (!order.totalpoint) {
      alert("Нийт оноо тооцоологдоогүй байна!");
      return false;
    } else {
      return true;
    }
  };
  // 4-р алхам баазруу захиалгаа хийх
  const setOrderToBase = async () => {
    const basketname = prompt("Сагсандаа нэр өгнө үү");
    order.orderid = orderIdGen(); // захиалгийн дугаар үүсгэх
    order.orderdate = ordeDate(); // захиалгын өдөр үүсгэх
    order.sysdate = new Date().toString(); // захиалга хийсэн цаг
    const data = {
        user:localStorage.getItem("userId"),
        basketname: basketname,
        products:ctx.basket,
        active:true,
        date:ordeDate(),
    };
    const arr = [];
    const snapshot = await firebase.firestore().collection("userbaskets").where("basketname", "==",basketname).get();
    snapshot.docs.map(doc => arr.push(doc.data()));
        
        if(arr.length==0){
        
        await firebase.firestore().collection('userbaskets').doc(basketname).set(data);
        ctx.clearBasket();
        alert("Амжилттай");
        window.location.href='/home'
        
        } else {
          arr.find((el)=>el.user == JSON.parse(localStorage.getItem('user')).id) ? alert("Танд энэ нэртэй сагс үүссэн байна") : await firebase.firestore().collection('userbaskets').doc(basketname+(arr.length+1).toString()).set(data);
        }
   
    ctx.setIsLoading(false);
    
  };
  const orderIdGen = () => {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    var tt = today.getHours();
    var min = today.getMinutes();
    var sec = today.getSeconds();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (min < 10) {
      min = "0" + min;
    }
    if (sec < 10) {
      sec = "0" + sec;
    }
    return "" + yyyy + mm + dd + tt + min + sec + order.userno;
  };
  const ordeDate = () => {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return yyyy + "/" + mm + "/" + dd;
  };
  return (
    <div className={css.kqrKyZ}>
      {ctx.isloading ? (
        <div className={css.loader}></div>
      ) : (
        <div>
          <form>
            <div className={css.dcTgel}>
              <div className={css.hGqnHy}>
                <div className={css.hwlTmd}>
                  
                  
                 
                </div>
                <CheckoutDetail key={0} order={order}></CheckoutDetail>
              </div>
            </div>
          </form>
          <button onClick={()=>submitForm()} className={css.savebtn}>Хадгалах</button>
        </div>
      )}
    </div>
  );
};
export default withRouter(Index);
