// src/components/CourseDetail.js
import React from "react";
import { useParams, useHistory } from "react-router-dom"; // Using useHistory instead of useNavigate

const CourseDetail = ({ courses, loading }) => {
  const { id } = useParams();
  const history = useHistory(); // Use useHistory hook

  if (loading) {
    return <div className="flex justify-center p-8">Loading...</div>;
  }

  if (!courses) {
    return <div className="text-center p-8">Course not found</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <button
        onClick={() => history.goBack()} // Use history.goBack() instead of navigate(-1)
        className="mb-4 text-blue-500 hover:text-blue-600"
      >
        ← Back to List
      </button>

      <div className="bg-white rounded-lg shadow-md p-6">
        <h1 className="text-2xl font-bold mb-4">Course Detail</h1>
        <div className="mt-6">
          <div
            className="embed-container border rounded-lg p-4"
            dangerouslySetInnerHTML={{ __html: courses.embedContent }}
          />
        </div>
      </div>
    </div>
  );
};

export default CourseDetail;
