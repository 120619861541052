import React from "react";

import "./style.css";

export const SearchBox = (props) => (
    <input
        className="search-box"
        type="search"
        value={props.searchField}
        placeholder="Бараануудаас хайх"
        onChange={props.onSearch}
    />
);
