import css from './style.module.css'
const Index = (props) => {
    // console.log("props", props)
    return (
        props.isLoading ? <h1>Loading</h1> : <div>
            <div className="form-group">
                <label htmlFor="exampleInputId">Төрөлийн дугаар:</label>
                <input autoComplete="off" name='id' disabled={props.isUpdate && 'disabled'} value={props.productType.id} type="number" onChange={(event) => { props.handleInputChange(event) }} className="form-control" id="exampleInputId" placeholder="Төрөлийн дугаар" />
                <p className={css.errorMsg}>{props.errorMsg.id.show && props.errorMsg.id.message}</p>
            </div>
            <div className="form-group">
                <label htmlFor="exampleInputPassword1">Төрөлийн нэр:</label>
                <input autoComplete="off" name='name' value={props.productType.name} type="text" onChange={(event) => { props.handleInputChange(event) }} className="form-control" placeholder="Төрөлийн нэр" />
                <p className={css.errorMsg}>{props.errorMsg.name.show && props.errorMsg.name.message}</p>

            </div>
            <div className="form-group">
                <label htmlFor="exampleInputPassword1">Төрөлийн эрэмбэ:</label>
                <input autoComplete="off" name='sorting' value={props.productType.sorting} type="number" onChange={(event) => { props.handleInputChange(event) }} className="form-control" placeholder="Төрөлийн эрэмбэ" />
                <p className={css.errorMsg}>{props.errorMsg.sorting.show && props.errorMsg.sorting.message}</p>
            </div>
            <div className="form-group">
                <label>Тайлбар:</label>
                <textarea className="form-control" value={props.productType.description
                } name="description" rows="3" onChange={(event) => { props.handleInputChange(event) }} placeholder="Тайлбар оруулах ..."></textarea>
                <p className={css.errorMsg}>{props.errorMsg.description.show && props.errorMsg.description.message}</p>
            </div>
            <div className="form-group">
                <label htmlFor="exampleInputFile">Tөрөлийн зураг:</label>
                {props.isUpdate && props.isUpdate === true && !props.mainimage ?
                    <div className="input-group">
                        <img className={css.inputImage} src={props.productType.image || "http://via.placeholder.com/20"} placeholder='Өмнөх зураг' alt='Өмнөх зураг' />
                    </div>
                    : ''
                }
                <div className="input-group">
                    <div className="custom-file">
                        <input type="file" name="image" onChange={(event) => { props.handleInputChange(event) }} id="exampleInputFile" />
                        <label className="custom-file-label" id='exampleInputFileLebel' htmlFor="exampleInputFile">Зураг сонгох...</label>
                    </div>

                </div>
                <p className={css.errorMsg}>{props.errorMsg.image.show && props.errorMsg.image.message}</p>
            </div>

            <div className="form-group">
                <label htmlFor="head">Өнгө</label>
                <input type="color" value={props.productType.color} name="color" onChange={(event) => { props.handleInputChange(event) }} className="form-control" />
            </div>
        </div>
    )
}

export default Index;
