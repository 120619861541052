import React, { Component } from "react";
import axios from "axios";
import css from "./style.module.css";
export default class index extends Component {
  render() {
    return (
      <div>
        <div className="row">
          <div>
            <span id="spanDate" className="boldStyle">
              Хэвлэсэн огноо :
            </span>
            <label id="labelDate">{this.props.date}</label>
            <br></br>
            <span className="boldStyle">Хүргэх утасны дугаар : </span>
            <label>{this.props.order.deliverynumber}</label>
            <br></br>
            <span className="boldStyle">ID дугаар: </span>
            <label>{this.props.order.deliveryaddress}</label>
            <br></br>
            <span className="boldStyle">15%-ийн нийт дүн : </span>
            <label>{this.props.order.totalprice}₮</label>
            <br></br>
            <span className="boldStyle">Данс : </span>
            <label>Хаан банк 5030155180 Бямбасүх</label>
            <br></br>

            {/* <span className="boldStyle">23%-ийн нийт дүн : </span>
            <label>{this.props.order.totalprice1}₮</label>
            <br></br> */}
            {/* <span className="boldStyle">100%-ийн нийт дүн:</span>
                    <label>{this.props.order.totalprice2}₮</label><br></br> */}
            <span className="boldStyle">Захиалгын оноо:</span>
            <label>{this.props.order.totalpoint}оноо</label>
          </div>
        </div>
        <div className={css.tableScroll}>
          <table
            id="orderTable1"
            className="table table-bordered table-striped"
          >
            <thead>
              <tr>
                <th>Нэр</th>
                <th>Оноо</th>
                <th>15% үнэ</th>
                {/* <th>23% үнэ</th> */}
                <th>Тоо</th>
              </tr>
            </thead>
            <tbody>
              {this.props.order.orderdetail &&
                this.props.order.orderdetail.map((el) => (
                  <tr>
                    <td>{el.name}</td>
                    <td>{el.point}</td>
                    <td>{el.price}</td>
                    {/* <td>{el.price1}</td> */}
                    <td>{el.quantity}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}
