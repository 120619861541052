import React, { Component } from 'react'
import firebase from '../../../../firebase'
import axios from '../../../../axios'
import css from './style.module.css'
export default class BannerDataTable extends Component {


    render() {
        return (
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">Баннер</h3>
                </div>
                <div className="card-body" >
                    <table id="example1" className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Дугаар</th>
                                <th>Эрэмбэ</th>
                                <th></th>
                                <th style={{ 'display': 'block' }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.bannerDatas.map((el, index) => {
                                return [<tr key={index}>
                                    <td className={css.CardBodyImgTd}><img className={css.CardBodyImg} src={el.image || "http://via.placeholder.com/50"} alt="firebase-image" /></td>
                                    <td>{el.id}</td>
                                    <td>{el.sort}</td>
                                    <td className={css.CardBodyEdit}><button id={css.CardIconCenter}
                                        data-toggle="modal" onClick={() => { this.props.setBanner(el, true, this.props.bannerDatas[index]) }} data-target="#modal-default" className="btn btn-success"><i className="fas fa-edit"></i></button>
                                    </td>
                                    <td className={css.CardBodyEdit}><button onClick={() => { this.props.deleteBanner(el.id) }} id={css.CardIconCenter} className="btn btn-danger"><i className="fas fa-trash-alt"></i></button></td>
                                </tr>]
                            })}
                        </tbody>

                        <tfoot>
                            <tr>
                                <th></th>
                                <th>Дугаар</th>
                                <th>Эрэмбэ</th>
                                <th></th>
                                <th style={{ 'display': 'block' }}></th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>

        )
    }
}
