import React, {useContext} from 'react';
import {BasketContext} from '../../../context/BasketContext'
import css from './style.module.css'
import {BasketDetail} from '../BasketDetail'
export const Basket = (props) => {
    let ctx = useContext(BasketContext);
    // console.log('basket: ',ctx.basket);
    const totalPrice = ctx.basket.reduce((acc, curr) => acc + curr.price * curr.qty, 0);
    const totalPrice1 = ctx.basket.reduce((acc, curr) => acc + curr.price1 * curr.qty, 0);
    // const totalPrice2 = ctx.basket.reduce((acc, curr) => acc + curr.price2 * curr.qty, 0);
    // console.log('basket from item:', ctx.basket);
    return (
        <div>
            <button className={css.jVFuuY} onClick={() => {ctx.setShow(ctx.show = true)} }>
                <span className={css.KDupa}><i className="fa fa-shopping-basket"></i>{ctx.basket.length} Бүтээгдэхүүн</span>
                <span className={css.dCrbXJ}>₮{totalPrice}</span>
            </button>
            {/* <BasketDetail></BasketDetail> */}
            {/* <button className={css.marginRight + " btn btn-success"} data-toggle="modal" data-target="#modal-default" ><i className="fas fa-plus"></i> Нэмэх</button> */}
            {/* <div className="modal" id="modal-default" >
                <div className="modal-dialog" style={{'position':'absolute','bottom':'0','margin':'0','width':'100%'}}>
                    <div className="modal-content">
                        <div className="modal-body"> */}
            {ctx.show && <BasketDetail></BasketDetail>}
            {/* </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}