import { useEffect, useState } from "react";
import firebase from "../../../../firebase";
import css from "./style.module.css";
import ProductTypeItem from "../ProductTypeItem";
import { ProductItem } from "../ProductItem";
import { Accordion } from "react-bootstrap";
import AccordionItem from "react-bootstrap/esm/AccordionItem";
import Slider from "../Page1";
import CourseList from "../CourseList";
import CourseContent from "../../../admin/Content/courses/courseContent";
const Index = (props) => {
  const [listOfTypes, setListOfTypes] = useState([]);
  const [favs, setfavs] = useState([]);
  const [userbaskets, setuserbaskets] = useState([]);
  const [loading, setLoading] = useState([]);
  const [type, settype] = useState("brand");
  const [courses, setCourses] = useState([]);
  const courseContent = new CourseContent();
  const fetchbaskets = async () => {
    const arr = [];
    const snapshot = await firebase
      .firestore()
      .collection("userbaskets")
      .where("user", "==", JSON.parse(localStorage.getItem("user")).id)
      .get();
    snapshot.docs.map((doc) => arr.push(doc.data()));
    setuserbaskets(arr);
  };
  const fetchCourses = async () => {
    try {
      const snapshot = await firebase
        .firestore()
        .collection("courses")
        .orderBy("createdAt", "desc")
        .get();

      const coursesData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCourses(coursesData);
    } catch (error) {
      this.setState({ error: error.message, loading: false });
    }
  };
  useEffect(async () => {
    setLoading(true);
    await fetchCourses();

    switch (type) {
      case "brand":
        firebase
          .firestore()
          .collection("productType")
          .onSnapshot((querySnapshot) => {
            let productTypeDatas = [];
            querySnapshot.forEach(function (doc) {
              // console.log(doc.id, " => ", doc.data());
              if (doc.data() != null) productTypeDatas.push(doc.data());
            });
            setListOfTypes(bubbleSort(productTypeDatas));
          });
        break;
      case "favs":
        firebase
          .firestore()
          .collection("favs")
          .doc(JSON.parse(localStorage.getItem("user")).id)
          .get()
          .then(async (doc) => {
            if (doc.exists) {
              setfavs(doc.data().products);
            }
          });
        break;
      case "baskets":
        fetchbaskets();
        break;
      default:
        firebase
          .firestore()
          .collection("productType")
          .onSnapshot((querySnapshot) => {
            let productTypeDatas = [];
            querySnapshot.forEach(function (doc) {
              // console.log(doc.id, " => ", doc.data());
              if (doc.data() != null) productTypeDatas.push(doc.data());
            });
            setListOfTypes(bubbleSort(productTypeDatas));
          });
    }

    setLoading(false);
  }, [type]);

  const bubbleSort = (inputArr) => {
    let len = inputArr.length;
    for (let i = 0; i < len - 1; i++) {
      for (let j = 0; j < len - i - 1; j++) {
        if (
          parseInt(inputArr[parseInt(j)].sorting) >
          parseInt(inputArr[parseInt(j) + 1].sorting)
        ) {
          let tmp = inputArr[j];
          inputArr[j] = inputArr[j + 1];
          inputArr[j + 1] = tmp;
        }
      }
    }
    return inputArr;
  };

  const removeUserBasket = async (obj) => {
    if (window.confirm("Сагс устгах уу ?")) {
      let data = obj;
      data.active = false;

      await firebase
        .firestore()
        .collection("userbaskets")
        .doc(obj.basketname)
        .set(data);
      fetchbaskets();
    }
  };

  // console.log("props", props);
  return loading ? (
    <div className={css.loader}></div>
  ) : (
    <div
      className="rowd-flex justify-content-center"
      style={{ paddingBottom: "100px" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          marginTop: "20px",
          cursor: "pointer",
        }}
      >
        <p
          style={
            type == "brand"
              ? { borderBottom: "2px solid orange", fontWeight: "bold" }
              : null
          }
          onClick={() => settype("brand")}
        >
          Брэндээр
        </p>
        <p
          style={
            type == "favs"
              ? { borderBottom: "2px solid orange", fontWeight: "bold" }
              : null
          }
          onClick={() => settype("favs")}
        >
          Хадгалсан
        </p>
        <p
          style={
            type == "baskets"
              ? { borderBottom: "2px solid orange", fontWeight: "bold" }
              : null
          }
          onClick={() => settype("baskets")}
        >
          Сагснууд
        </p>
        <p
          style={
            type == "courses"
              ? { borderBottom: "2px solid orange", fontWeight: "bold" }
              : null
          }
          onClick={() => settype("courses")}
        >
          Сургалт
        </p>
      </div>
      {type == "brand" ? (
        <div>
          <div>
            <Slider />
          </div>
          <div className={css.cardList}>
            {listOfTypes.map((item, index) => {
              return (
                <ProductTypeItem key={index} item={item}></ProductTypeItem>
              );
            })}
          </div>
        </div>
      ) : null}
      {type == "courses" ? (
        <div>
          <CourseList courses={courses} />
        </div>
      ) : null}
      {type == "favs" && favs.length > 0 ? (
        <div>
          <div>
            <Slider />
          </div>
          <div className={css.cardList}>
            {favs.map((item) => (
              <ProductItem key={item.id} product={item}></ProductItem>
            ))}
          </div>
        </div>
      ) : type != "brand" && type != "baskets" ? (
        <div>
          <div
            style={{
              height: "100vh",
              flexDirection: "column",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              height="400px"
              width="400px"
              alt="Russia Image"
              src={process.env.PUBLIC_URL + "/dist/img/empty.png"}
            />
            <p className={css.producttitle}>Бараа байхгүй байна</p>
          </div>
        </div>
      ) : null}
      {type == "baskets" ? (
        <div>
          <div>
            <Slider />
          </div>
          <Accordion style={{ width: "100vw" }}>
            {userbaskets.map((obj, index) => {
              let total = 0;
              let totalpoint = 0;
              obj.products.map(
                (prod) => (total = total + prod.qty * parseInt(prod.price))
              );
              obj.products.map(
                (prod) =>
                  (totalpoint = parseFloat(
                    (totalpoint + prod.qty * prod.point).toFixed(2)
                  ))
              );
              return obj.active ? (
                <Accordion.Item eventKey={index.toString()}>
                  <Accordion.Header>{obj.basketname}</Accordion.Header>
                  <Accordion.Body>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "15px",
                        padding: "0px 10px",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ fontSize: "small" }}>{obj.date}</span>
                      <span style={{ fontWeight: "bold", color: "#FFB369" }}>
                        {total}₮
                      </span>
                      <span style={{ fontSize: "small" }}>
                        {totalpoint} оноо
                      </span>
                    </div>
                    <div className={css.cardList}>
                      {obj.products.map((item) => (
                        <ProductItem
                          type={"userbasket"}
                          key={item.id}
                          product={item}
                        ></ProductItem>
                      ))}
                    </div>
                    <div
                      style={{
                        marginTop: "15px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <button
                        className={css.deletebtn}
                        onClick={() => {
                          removeUserBasket(obj);
                        }}
                      >
                        <i className="fa fa-shopping-basket"></i>
                      </button>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <div></div>
              );
            })}
          </Accordion>
        </div>
      ) : null}
      <div>
        <h1>Product Type List</h1>

        {/* Other components or content */}
      </div>
    </div>
  );
};
export default Index;
