import React, { Component } from 'react'
import css from './style.module.css'
import { NavLink } from 'react-router-dom'
import { ExchangeContext } from '../../context/ExchangeContext'
import avatar from '../../../assets/avatardefault.png'
export const UserHeader = () => {
  const ctx = React.useContext(ExchangeContext);
  var exchange = ctx.state.exchange;
  // if (localStorage.getItem("exchange"))
  //   exchange.data = JSON.parse(exchange.data);
  React.useEffect(() => {
    ctx.getExchange()
  }, [])

  return (<nav className="shadow-sm navbar navbar-expand navbar-white navbar-light" style={{background: "#8e2de2",  /* fallback for old browsers */
  background: '-webkit-linear-gradient(to right, #8e2de2, #4a00e0)', /* Chrome 10-25, Safari 5.1-6 */
  background: 'linear-gradient(to right, #8e2de2, #4a00e0)' /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }}>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      {/* <div className={css.rub}>
        <div className={css.rub2}>
          <img height="40px" width="50px" alt="Russia Image" src={process.env.PUBLIC_URL + "/dist/img/rus.png"} />
          {ctx.state.loading ? <div className={css.loader}></div> : <div className={css.dada}>
            <p className={css.haha}>{ctx.state && exchange && exchange.data.code + "=" + exchange.data.rate + "₮"}</p>
            <p className={css.haha}>{ctx.state && exchange && exchange.data.name}</p>
          </div>}
        </div>
        <p className={css.dateP}>Сүүлд шинэчлэгдсэн: {ctx.state && exchange && exchange.data.last_date}</p>
      </div> */}
      <ul className="navbar-nav" style={{padding:'5px',paddingLeft:'10px',}}>
        <li>
          <div data-toggle="dropdown" style={{display:'flex',justifyContent:'space-between',alignItems:'center',width:'90vw',}}>
          {/* <div style={{display:'flex',alignItems:'center',gap:'10px', }}> */}
          <img width="50px" height="50px" src={avatar} className="img-circle elevation-2" />
          <p style={{margin:'0',fontWeight:'600',color:'white',marginRight:'20px'}}>{JSON.parse(localStorage.getItem("user")).phoneNumber}</p>
          {/* </div> */}
          <i className='fa fa-bars' style={{color:'white',fontSize:'large'}}></i>
          </div>
          <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
            <NavLink className="dropdown-item" to="/home" id="home">Нүүр</NavLink>
            <NavLink className="dropdown-item" to="/profile" id="profile">Профайл</NavLink>
            <NavLink className="dropdown-item" to="/checkout" id="checkout">Захиалах</NavLink>
            <NavLink className="dropdown-item" to="/YourOrder" id="myorder">Миний захиалгууд</NavLink>
            <a className="dropdown-item" href="/logout" id="logout">Гарах</a>
          </div>
        </li>
      </ul>
    </div>
  </nav>
  )
}
