import { Link, Router } from 'react-router-dom'
import css from './style.module.css'

import { withRouter } from 'react-router-dom'
import React from 'react'
const Index = (props) => {

    // console.log("typeItem", props);
    const getProductList = (id) => {
        const param = "typeId=" + id
        props.history.push({
            pathname: '/productlist',
            search: param
        })
    }
    return (
        <div className={css.cardContainer} onClick={() => { getProductList(props.item.id) }}>
            {/* <p className={css.text}>{props.item.name}</p>  style={{ background: props.item.color }}*/}
            <img width="100%" height="100%" src={props.item.image}></img>
        </div>
    )
}

export default withRouter(Index)
