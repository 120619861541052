import React from 'react';
import css from './style.module.css'
class Index extends React.Component {

    render() {
        return (
            <div className="modal fade bd-example-modal-sm" id="modal-address" >
                <button type="button" className={css.reuseModalCloseBtn} data-dismiss="modal" aria-label="Close">
                    <i className='fa fa-times'></i>
                </button>
                <div className="modal-dialog modal-sm modal-dialog-centered">
                    <div className="modal-content">
                        <div className={css.innerRndComponent}>
                            <form action='#'>
                                <div className={css.bHlADF}>Шинэ хаяг нэмэх</div>
                                <div className={css.deMkW}>
                                    <div>
                                        <input type='text' value={this.props.data.name} name='name' placeholder='Хаягын нэр' onChange={(event)=>this.props.handleInputChange(event)} className={css.iapefQ}></input>
                                    </div>
                                </div>
                                <div className={css.deMkW}>
                                    <div className={css.bUSVGR}>
                                        <textarea name='val' value={this.props.data.val} placeholder='Дэлгэрэнгүй хаяг' onChange={(event)=>this.props.handleInputChange(event)} className={css.iapefQ}></textarea>
                                    </div>
                                </div>
                                <button type='button' className={css.dwDYsr} data-dismiss="modal" aria-label="Close" disabled={this.props.formValid ? "" : "disabled"} onClick={() => {this.props.addfunc()}}>{this.props.data.isupdate?'Хаяг засварлах':'Хаяг хадгалах'}</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Index;
