import React from 'react';
import css from './style.module.css'
const Index = (props) => {

    return (
        props.isLoading ? <h1>Loading</h1> : <div>
            <div className="form-group">
                <label htmlFor="exampleInputId">Барааны код:</label>
                <input disabled={props.isUpdate && 'disabled'} name='id' id='exampleInputId' value={props.product.id} type="number" onChange={(event) => { props.handleInputChange(event) }} className="form-control" placeholder="Барааны код" autoComplete="off" />
                <p className={css.errorMsg}>{props.errorMsg.id.show && props.errorMsg.id.message}</p>
            </div>
            <div className="form-group">
                <label htmlFor="exampleInputPassword1">Барааны нэр:</label>
                <input name='name' value={props.product.name} type="name" onChange={(event) => { props.handleInputChange(event) }} className="form-control" placeholder="Барааны нэр" autoComplete="off" />
                <p className={css.errorMsg}>{props.errorMsg.name && props.errorMsg.name.show && props.errorMsg.name.message}</p>
            </div>
            <div className="form-group">
                <label>Барааны төрөл:</label>
                <select name='type' value={props.product.type} onChange={(event) => { props.handleInputChange(event) }} className="form-control">
                    <option value=''></option>
                    {props.productTypes.map((el) => {
                        return (<option key={el.id} value={el.id}>{el.name}</option>)
                    })}
                </select>
                <p className={css.errorMsg}>{props.errorMsg.type.show && props.errorMsg.type.message}</p>
            </div>
            <div className="form-group">
                <label>Барааны үнэ:</label>
                {/* <input type="number" value={props.product.price} min="0" name="price" className="form-control" onChange={(event) => { props.handleInputChange(event) }} placeholder="Барааны үнэ" autoComplete="off"/> */}
                <div className={css.productPrice}>
                    <div class="col-md">
                        <input name="price" value={props.product.price} onChange={(event) => { props.handleInputChange(event) }} type="number" placeholder="₮‎" autoComplete="off" min="0"></input>
                        <p className={css.errorMsg}>{props.errorMsg.price.show && props.errorMsg.price.message}</p>
                    </div>
                    {/* <div class="col-md">
                        <input name="price1" value={props.product.price1} type="number" onChange={(event) => { props.handleInputChange(event) }} placeholder="23%" autoComplete="off" min="0"></input>
                        <p className={css.errorMsg}>{props.errorMsg.price1.show && props.errorMsg.price1.message}</p>
                    </div> */}
                </div>
            </div>
            {/* <div className="form-group">
                <label>Онцлог:</label>
                <textarea value={props.product.specialDescription} className="form-control" name="specialDescription" rows="3" onChange={(event) => { props.handleInputChange(event) }} placeholder="Тайлбар оруулах ..."></textarea>
                <p className={css.errorMsg}>{props.errorMsg.specialDescription.show && props.errorMsg.specialDescription.message}</p>
            </div>
            <div className="form-group">
                <label>Ашиг тус:</label>
                <textarea value={props.product.goodDescription} className="form-control" name="goodDescription" rows="3" onChange={(event) => { props.handleInputChange(event) }} placeholder="Тайлбар оруулах ..."></textarea>
                <p className={css.errorMsg}>{props.errorMsg.goodDescription.show && props.errorMsg.goodDescription.message}</p>
            </div> */}
            {/* <div className="form-group">
                <label>Хэрэглэх заавар :</label>
                <textarea value={props.product.bodyDescription} className="form-control" name="bodyDescription" rows="3" onChange={(event) => { props.handleInputChange(event) }} placeholder="Тайлбар оруулах ..."></textarea>
                <p className={css.errorMsg}>{props.errorMsg.bodyDescription.show && props.errorMsg.bodyDescription.message}</p>
            </div> */}
            <div className="form-group">
                <label>Дэлгэрэнгүй :</label>
                <textarea value={props.product.description} className="form-control" name="description" rows="3" onChange={(event) => { props.handleInputChange(event) }} placeholder="Тайлбар оруулах ..."></textarea>
                <p className={css.errorMsg}>{props.errorMsg.description.show && props.errorMsg.description.message}</p>
            </div>
            <div className="form-group">
                <label>Оноо:</label>
                <input name="point" value={props.product.point} type="number" min="0" className="form-control" rows="3" onChange={(event) => { props.handleInputChange(event) }} placeholder="Оноо оруулах ..." autoComplete="off" />
                <p className={css.errorMsg}>{props.errorMsg.point.show && props.errorMsg.point.message}</p>
            </div>
            {/* <div className="form-group">
                <label>Тоо ширхэг:</label>
                <input name="quantity" value={props.product.quantity} type="number" min="0" className="form-control" rows="3" onChange={(event) => { props.handleInputChange(event) }} placeholder="Тоо ширхэг оруулах ..." autoComplete="off" />
                <p className={css.errorMsg}>{props.errorMsg.quantity.show && props.errorMsg.quantity.message}</p>
            </div> */}
            <div className="form-group">
                <label htmlFor="exampleInputFile">Барааны зураг:</label>
                {props.isUpdate && props.isUpdate === true && !props.mainimage ?
                    <div className="input-group">
                        <img className={css.inputImage} src={props.product.image || "http://via.placeholder.com/20"} placeholder='Өмнөх зураг' alt='Өмнөх зураг' />
                    </div>
                    : ''
                }
                <div className="input-group">
                    <div className="custom-file">
                        <input type="file" name="image" onChange={(event) => { props.handleInputChange(event) }} id="exampleInputFile" />
                        {/* <label className="custom-file-label" id='exampleInputFileLebel' htmlFor="exampleInputFile">Зураг сонгох...</label> */}
                    </div>

                </div>
                <p className={css.errorMsg}>{props.errorMsg.image.show && props.errorMsg.image.message}</p>
            </div>
        </div>
    )
}

export default Index;
