import React from 'react'
import {BasketContext} from '../../../context/BasketContext'

import css from './style.module.css'

export const CheckoutDetail = (props) => {
    const {basket} = React.useContext(BasketContext);
    // console.log('order basket:',basket);
    return (
        <div className={css.gwBSuY}>
            {props.order &&
            <div className='sticky-outer-wrapper'>
                <div className={css.kSbJGA}>
                    <h3 className={css.dAPHLO}>Сонгосон бараанууд</h3>
                    <div className={css.checkoutscrollbar}>
                        <div className={css.ospadding}>
                            <div className={css.hXnywk}>
                                {
                                    basket && basket.map((item)=>{
                                    return <div key={item.id} className={css.fBhgXs}>
                                        <span className={css.iPCvVb}>1</span>
                                        <span className={css.kZXmaV}>x</span>
                                        <span className={css.djStHc}>{item.qty}&nbsp;{item.name}</span>
                                        <div className={css.flexrow}>
                                            <span className={css.jDKalT}>{item.price * item.qty}₮</span>
                                            <span className={css.jDKalT}>{item.price1 * item.qty}₮</span>
                                            {/* <span className={css.jDKalT}>{item.price2 * item.qty}₮</span> */}
                                        </div>
                                    </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className={css.vNVrf}>
                        {/* delivery discount ашиглавал үүнийг хийнэ */}
                        {/* <div className={css.iZImhr}>
                            <span className={css.kTPYoz}>Ханш</span>
                            <span className={css.kTPYoz}>{props.order.rate}&#8366;</span>
                        </div> */}
                        <div className={css.iZImhr}>
                            <span className={css.kTPYoz}>Нийт үнэ</span>
                            <span className={css.kTPYoz}>{props.order.totalprice}₮</span>
                        </div>
                        <div className={css.iZImhr}>
                            {/* <span className={css.kTPYoz}>Нийт үнэ23%</span> */}
                            {/* <span className={css.kTPYoz}>{props.order.totalprice1}₮</span> */}
                        </div>
                        {/* <div className={css.iZImhr}>
                            <span className={css.kTPYoz}>Нийт үнэ100%</span>
                            <span className={css.kTPYoz}>{props.order.totalprice2}₮</span>
                        </div> */}
                        <div className={css.iZImhr}>
                            <span className={css.kxbXCM}>Нийт оноо</span>
                            <span className={css.kxbXCM}>{props.order.totalpoint}</span>
                        </div>
                    </div>
                </div> 
            </div>
            }
        </div>
    )
}
