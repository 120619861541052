import React from "react";
import { Webview } from "../../Component/OrderItem/webv.js";
import { WebViewDetail } from "../../Component/OrderItem/webViewDetail.js";
import { MobileView } from "../../Component/OrderItem/mobilev.js";
import firebase from "../../../../firebase";
import { OrderStatuses } from "../../../../util/OrderStatuses";
import { UserContext } from "../../../context/UserContext";

import css from "./style.module.css";
import css2 from "../../Component/OrderItem/style.module.css";
export const Order = () => {
  const [listOfOrders, setListOfOrders] = React.useState([]);
  const [orderDetail, setOrderDetail] = React.useState(null);
  const [orderProducts, setOrderProducts] = React.useState([]);
  const [loading, setLoading] = React.useState([]);
  const ctx = React.useContext(UserContext);
  const objuser = JSON.parse(ctx.state.user);
  // console.log('objuser:', objuser);

  React.useEffect(() => {
    let productArray = [];
    setLoading(true);
    firebase
      .firestore()
      .collection("order")
      .where("email", "==", objuser.email)
      .where("userno", "==", objuser.phoneNumber)
      .orderBy("orderid", "asc")
      .get()
      .then((querySnapshot) => {
        const productTypeDatas = [];
        querySnapshot.forEach(function (doc) {
          if (doc.data() != null) {
            let temporder = doc.data();
            let statusname = OrderStatuses(temporder.orderstatus);
            temporder.orderstatusname = statusname.name;
            productTypeDatas.push(temporder);
            doc
              .data()
              .orderdetail.map(
                (item) =>
                  productArray.indexOf(item.id) == -1 &&
                  productArray.push(item.id)
              );
          }
        });
        // start setOrderProducts
        if (productArray.length > 0) {
          for (let i = 0; i < productArray.length; i = i + 9) {
            orderProduct(productArray.slice(i, i + 9));
          }
        }

        // end setOrderProducts
        setListOfOrders([...productTypeDatas.reverse()]);
        setOrderDetail(
          productTypeDatas.length > 0 ? productTypeDatas[0] : null
        );
        setLoading(false);
      });
  }, []);

  const orderProduct = (pproductArray) => {
    
    firebase
      .firestore()
      .collection("product")
      .where("id", "in", [...pproductArray])
      .get()
      .then((querySnapshot2) => {
        querySnapshot2.forEach(function (doc2) {
          if (doc2.data() != null) orderProducts.push(doc2.data());
        });
        setOrderProducts(orderProducts);
      });
  };

  const changeDetail = (id, type) => {
    if (type == "mob") {
      if (orderDetail && id == orderDetail.orderid) {
        setOrderDetail(null);
        return;
      }
    }
    listOfOrders.forEach((itemm) => {
      if (itemm.orderid == id) {
        // console.log('item',itemm);
        setOrderDetail(itemm);
      }
    });
  };
  return (
    <div className={css.eXUcye}>
      <div className={css.TVBQD}>
        <div className={css.duuioQ}>
          <div className={css.fgelJT}>
            <h3 className={css.kVstku}>Миний захиалгууд</h3>
            <div
              className={css.bGLdjy}
              onChange={(event) => {
                changeDetail(event.target.id, "web");
              }}
            >
              {listOfOrders.length > 0 &&
                listOfOrders.map((item, key) => (
                  <label
                    key={"WebviewLabel" + key}
                    htmlFor={item.orderid}
                    className={
                      css.dEBCwJ +
                      (orderDetail && item.orderid == orderDetail.orderid
                        ? " " + css.active
                        : " ")
                    }
                  >
                    <input
                      type="radio"
                      id={item.orderid}
                      name="webviewradio"
                      value={item.id}
                    ></input>
                    <Webview
                      key={"Webview" + key}
                      numb={item.orderid}
                      orderdate={item.orderdate}
                      totalpoint={item.totalpoint}
                      statusname={item.orderstatusname}
                      changeDetail={changeDetail}
                    ></Webview>
                  </label>
                ))}
            </div>
          </div>
          <WebViewDetail
            key={"WebViewDetail0"}
            orderDetail={orderDetail}
            orderProducts={orderProducts}
          ></WebViewDetail>
        </div>
        <div className={css2.fDCATW}>
          <div className={css2.bGLdjy}>
            <div className={css2.rccollapse} role="tablist">
              {listOfOrders.length > 0 &&
                listOfOrders.map((item, key) => (
                  <MobileView
                    key={"MobileView" + key}
                    numb={item.orderid}
                    myorder={item}
                    active={
                      orderDetail && orderDetail.orderid == item.orderid
                        ? true
                        : false
                    }
                    orderProducts={orderProducts}
                    changeDetail={changeDetail}
                  ></MobileView>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
