import firebase from 'firebase'
const config = {
    apiKey: "AIzaSyCr-BrZe99Ykvh_tcjZF31m-0VOwmslNzA",
    authDomain: "onlineshop-29d11.firebaseapp.com",
    databaseURL: "https://onlineshop-29d11-default-rtdb.firebaseio.com",
    projectId: "onlineshop-29d11",
    storageBucket: "onlineshop-29d11.appspot.com",
    messagingSenderId: "686970152440",
    appId: "1:686970152440:web:b8638c716ac161540da20d"
};
var fire = firebase.initializeApp(config);
export default fire;

