import React from 'react'

import css from './style.module.css'

export const ProfileDetail = (props) => { 
        return (
            <div className={css.gwBSuY}>
                {props.user &&
                <div className='sticky-outer-wrapper'>
                    <div className={css.kSbJGA}>
                        <h1 className={css.dAPHLO}>Профайл</h1>
                        <div className={css.colDIV}>
                            <label className={css.phoneLbl}>Утасны дугаар</label>
                            <input readOnly value={props.user.phoneNumber} className="form-control" type="text"></input>
                        </div>
                        <div className={css.colDIV2}>
                            <label className={css.phoneLbl}>И-мэйл хаяг</label>
                            <input readOnly value={props.user.email} className="form-control" type="text"></input>
                        </div>
                        <button onClick={()=>{props.submitprofile()}} className={css.subBtn} type="button">Хадгалах</button>
                    </div> 
                </div>
                }
            </div>
        )
    }
