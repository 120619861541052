import React from 'react'
import css from './style.module.css'
import ProductAdd from '../ProductAdd'
const ProductControl = (props) => {
    const productFormClose = () => {
        document.getElementById("exampleInputFile").value = '';
    }
    
    return (
        <div className="col-sm-12 text-right">
            <button onClick={() => {
                props.setProduct();
            }} className={css.marginRight + " btn btn-success"} data-toggle="modal" data-target="#modal-default" ><i className="fas fa-plus"></i> Нэмэх</button>
            <input id="textInput" style={{marginLeft:'10px'}} type="file" onChange={(event) => { props.fileHandler(event) }} />
            

            <div className="modal fade" id="modal-default" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Бараа нэмэх</h4>
                            <button id="exit" type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { productFormClose() }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        {props.isLoading == true ? <div className={css.loader}></div> : <div className="modal-body">
                            <div className="card card-primary" >
                                <div className="card-body" style={{ "textAlign": "left" }}>
                                    <ProductAdd mainimage={props.mainimage} productAction={props.productAction} isLoading={props.isLoading} product={props.product} errorMsg={props.errorMsg} handleInputChange={props.handleInputChange} isUpdate={props.isUpdate} productTypes={props.productTypes}></ProductAdd>
                                    {props.isUpdate == true ? <div className="modal-footer justify-content-between">
                                        <button type="button" className="btn btn-danger" data-dismiss="modal">Хаах</button>
                                        <button type="submit" onClick={props.productAction} disabled={props.formValid ? "" : "disabled"} className="btn btn-success">Засах</button>
                                        <button type="button" onClick={props.refreshProduct} className="btn btn-info"><i className="fas fa-sync-alt"></i></button>
                                    </div>
                                        : <div className="modal-footer justify-content-between">
                                            <button type="button" className="btn btn-danger" onClick={() => { productFormClose() }} data-dismiss="modal">Хаах</button>
                                            <button type="submit" onClick={props.productAction} disabled={props.formValid ? "" : "disabled"} className="btn btn-success">Хадгалах</button>
                                        </div>}
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>

        </div >
    )
}

export default ProductControl;