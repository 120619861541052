import React, { Component } from "react";
import DataTable from "../../DataTable/banner/index";

import $ from "jquery";
import Resizer from "react-image-file-resizer";
import { validation } from "../../../../util/Validator";
import firebase from "./../../../../firebase";
import "react-confirm-alert/src/react-confirm-alert.css";
import css2 from "../../components/ProductContol/style.module.css";

import css from "../../DataTable/products/style.module.css";
import BannerControl from "../../components/BannerControl/index";
class BannerContent extends React.Component {
  defaultStatus = {
    bannerLoading: false,
    isUpdate: false,
    mainimage: "",
    banner: {
      id: "",
      sort: "",
      image: "",
    },
    errorMsg: {
      id: { show: false },
      sort: { show: false },
      image: { show: false },
    },
  };
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.bannerAction = this.bannerAction.bind(this);
    this.setUpdateBanner = this.setUpdateBanner.bind(this);
    this.setAddBanner = this.setAddBanner.bind(this);
    this.deleteBanner = this.deleteBanner.bind(this);
    this.state = {
      ...this.defaultStatus,
      bannerDatas: [],
      tableLoading: true,
      banners: [
        {
          id: "",
          sort: "",
          image: "",
        },
      ],
    };
  }

  tempBanner = {};
  bannerAdd(banner, e) {
    this.setState({ bannerLoading: true });
    firebase
      .firestore()
      .collection("banner")
      .doc(banner.id)
      .set(banner)
      .then((ref) => {
        alert(this.state.isUpdate ? "амжилттай заслаа" : "амжилттай нэмлээ");
        if (this.state.isUpdate) {
          $("#exit").click();
        }
      })
      .catch((ex) => {
        console.log("servertei holbogdhod " + ex);
        alert("servertei hobogdhod aldaa garlaa", ex);
      })
      .finally(
        setTimeout(
          function () {
            this.setState({
              banner: {
                ...this.defaultStatus.banner,
              },
              bannerLoading: false,
            });
            this.getBannerDatas();
          }.bind(this),
          1000
        )
      );
  }
  getBannerDatas() {
    firebase
      .firestore()
      .collection("banner")
      .get()
      .then((querySnapshot) => {
        this.setState({ tableLoading: true });
        const bannerDatas = [];
        querySnapshot.forEach(function (doc) {
          bannerDatas.push(doc.data());
        });
        console.log("data", bannerDatas);
        if (document.getElementById("datatableId"))
          document.getElementById("datatableId").remove();
        const script = document.createElement("script");

        script.setAttribute("id", "datatableId");
        script.src = "../js/content_datatable.js";
        script.async = true;
        document.body.appendChild(script);
        this.setState({ bannerDatas: bannerDatas });
        this.setState({ tableLoading: false });
      });
  }
  deleteBanner(id) {
    this.setState({ tableLoading: true });
    firebase
      .firestore()
      .collection("banner")
      .doc(id)
      .delete()
      .then((res) => {
        alert("амжилттай устлаа");
      })
      .catch((ex) => {
        console.log(ex);
        alert("servertei holbogdhod aldaa garlaa" + ex);
      })
      .finally(
        setTimeout(
          function () {
            this.getBannerDatas();
          }.bind(this),
          1000
        )
      );
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const type = target.type;
    const validationTypes = {
      price: "POSITIVENUMBER",
      id: "EQUAL",
    };
    if (type == "file") {
      if (event.target.files[0]) {
        Resizer.imageFileResizer(
          event.target.files[0],
          700,
          700,
          "png",
          100,
          0,
          (uri) => {
            // console.log(uri.split(',')[1])
            this.setState({ mainimage: uri });
          },
          "base64",
          200,
          200
        );
      }
    }
    const validate = validation(
      value,
      validationTypes[name],
      name == "id" && this.state.bannerDatas
    );

    let formValid = false;

    if (!validate.show) {
      let istemvavlid = true;
      Object.values(this.state.banner).map((el, index) => {
        if (el.length == 0 && Object.keys(this.state.banner)[index] != [name]) {
          istemvavlid = false;
          return;
        }
      });
      if (istemvavlid) {
        formValid = true;
      }
    }
    this.setState({
      errorMsg: {
        ...this.state.errorMsg,
        [name]: validate,
      },
      banner: {
        ...this.state.banner,
        [name]: value,
      },
      formValid: formValid,
    });
  }

  bannerAction = () => {
    this.setState({ bannerLoading: true });
    if (!this.state.mainimage && this.state.isUpdate) {
      this.bannerAdd(this.state.banner);
    } else {
      firebase
        .storage()
        .ref(this.state.banner.id)
        .putString(this.state.mainimage.split(",")[1], `base64`, {
          contentType: `image/png`,
        })
        .then((responce) => {
          responce.ref.getDownloadURL().then((downloadURL) => {
            this.setState({
              banner: {
                ...this.state.banner,
                image: downloadURL,
              },
            });
            this.bannerAdd(this.state.banner);
          });
        });
    }
  };
  setUpdateBanner(updatedBanner, isUpdate) {
    this.setState({ bannerLoading: true });
    firebase
      .firestore()
      .collection("banner")
      .doc(String(updatedBanner.id))
      .get()
      .then((res) => {
        let banner = res.data();
        this.tempBanner = banner;
        this.setState({
          ...this.defaultStatus,
          mainimage: null,
          formValid: true,
          isUpdate: isUpdate,
          banner: banner,
        });
      });
  }
  setAddBanner() {
    this.setState({ ...this.defaultStatus, formValid: false });
  }

  componentDidMount() {
    this.getBannerDatas();
  }

  render() {
    return (
      <div className="content-wrapper">
        <div>
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <BannerControl
                  bannerDatas={this.state.bannerDatas}
                  mainimage={this.state.mainimage}
                  bannerAction={this.bannerAction}
                  formValid={this.state.formValid}
                  setBanner={this.setAddBanner}
                  banner={this.state.banner}
                  isUpdate={this.state.isUpdate}
                  isLoading={this.state.bannerLoading}
                  errorMsg={this.state.errorMsg}
                  handleInputChange={this.handleInputChange}
                ></BannerControl>
              </div>
            </div>
          </div>

          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  {this.state.tableLoading == true ? (
                    <div className={css.loader}></div>
                  ) : (
                    <DataTable
                      deleteBanner={this.deleteBanner}
                      setBanner={this.setUpdateBanner}
                      bannerDatas={this.state.bannerDatas}
                    />
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}
export default BannerContent;
