import React, { Component } from 'react'
import PopDetail from '../CheckoutDetailpopdetail'
// import Demo1 from '../Demo1'

import css from './style.module.css'

export default class CheckoutDetailPop extends Component {
    constructor() {
        super();
        this.state = {
            name: "React",
            active: 0
        };
    }
    render() {
        return (
            <div className={css.bCfOXr}>
                <h3 className={css.iDDlIV}>{this.props.option == "address" ? "Хүргэх хаяг": "Хүргэх утас"}</h3>
                <div className={css.dWZUxg}>
                    <div className={css.leCXcG}>
                        <div onChange={(event)=>{this.props.onChangeRadioValue(event)}}>
                            {this.props.data && this.props.data.map((el, index) =>{
                                return (<PopDetail key={index} id={index} radioname={this.props.option} radiodata={el} active={index == this.props.sel} setUpdateData={this.props.setUpdateData} delData={this.props.delData}></PopDetail>)
                            })}
                        </div>
                        <button type='button' onClick={() => {this.props.setAdd(this.props.option);}} className={css.jNBJTJ} data-toggle="modal" data-target={'#modal-'+this.props.option}>
                            <div className={css.hUFhpq}>
                                <i className='fa fa-plus'></i>
                            </div>
                            {`Add`}
                        </button>
                    </div>
                </div>
                {this.props.ispbutton &&
                    <div>
                        {/* <span className={css.eTKfHA}>{`Үйлчилгээний нөхцөл`}
                            <span className={css.jZnGlQ}>Энд дарж үзнэ үү</span>
                        </span> */}
                        <div className={css.ExTwM}>
                            <button type='button' onClick={()=>{this.props.ispbutton()}} className={css.ddKXqe}>Баталгаажуулах</button>
                        </div>
                    </div>
                }
            </div>
        )
    }
}
