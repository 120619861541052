import React from 'react'
import { ProductItem } from '../ProductItem'
import { useEffect, useState } from "react";
import firebase from "../../../../firebase";
import css from './style.module.css'
import { withRouter } from 'react-router-dom'
import fire from '../../../../firebase';
import { SearchBox } from '../SearchBox'
import Pagination from '../Pagination'
import { BasketContext } from '../../../context/BasketContext'
const ProductList = (props) => {
    const ctx = React.useContext(BasketContext);
    const [products, setProducts] = useState([]);
    const [lastDoc, setLastDoc] = useState([]);
    const [loading, setLoading] = useState([]);
    const [isEmpty, setIsEmpty] = useState([]);
    const [typeId, setTypeId] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [productsPerpage] = useState(15);
    const [searchField, setSearchField] = useState("");
    useEffect(() => {
        const params = new URLSearchParams(props.location.search);
        // console.log("typeId1: ", typeId);
        firebase.firestore().collection('product').where('type', '==', params.get("typeId")).orderBy('id', 'asc').onSnapshot((collections) => {
            const products = collections.docs.map((product) => product.data());
            const lastDoc = collections.docs[collections.docs.length - 1];
            // console.log(products);
            setProducts(products);
            setLastDoc(lastDoc);
            setLoading(false);
            setIsEmpty(false);
            setTypeId(params.get("typeId"));
            // console.log("typeId: ", typeId);
        })
    }, []);

    // const updateStates = (collections) => {
    //     const isCollectionEmpty = collections.size === 0;
    //     console.log("size", collections.size);
    //     if (!isCollectionEmpty) {
    //         const colors = collections.docs.map((product) => product.data());
    //         const lastDoc = collections.docs[collections.docs.length - 1];
    //         setProducts((products) => [...products, ...colors]);
    //         setLastDoc(lastDoc);
    //     } else {
    //         setIsEmpty(true);
    //     }
    //     setLoading(false);
    // };

    // const fetchMore = () => {
    //     if (!isEmpty) {
    //         setLoading(true);
    //         console.log('lastDoc:', lastDoc);
    //         console.log("fetchtypeId: ", typeId);
    //         firebase.firestore().collection('product').where('type', '==', typeId)
    //             .orderBy('id', 'asc').
    //             onSnapshot((collections) => {
    //                 const products = collections.docs.map((product) => product.data());
    //                 setProducts(products);
    //                 setLoading(false);
    //             });
    //     } else {
    //         console.log("duurseen");
    //     }
    // };


    const onSearchChanged = (event) => {
        setSearchField(event.target.value);
    };
    const filteredProducts = products.filter(
        (el) => {
            // console.log("test" + el.point.replace(/\s\s+/g, ' '), el.name.toLowerCase().includes(searchField.toLocaleLowerCase()));
            return el.name.toLowerCase().trim().replace(/\s\s+/g, ' ').includes(searchField.toLocaleLowerCase().trim()) ||
                el.price.toLowerCase().trim().replace(/\s\s+/g, ' ').includes(searchField.toLocaleLowerCase().trim()) ||
                el.point.toLowerCase().trim().replace(/\s\s+/g, ' ').includes(searchField.toLocaleLowerCase().trim()) ||
                el.id.toLowerCase().trim().replace(/\s\s+/g, ' ').includes(searchField.toLocaleLowerCase().trim())
        }
    );
    // const indexOfLastProduct = currentPage * productsPerpage;
    // const indexOfFirstProduct = indexOfLastProduct - productsPerpage;
    // const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
    // const paginate = (number) => {
    //     setSearchField("");
    //     setCurrentPage(number);

    // };
    return (
        ctx.isloading ? <div className={css.loader}></div> :
            loading ? <div className={css.loader}></div> :
                products.length === 0 ?
                    <div style={{
                        height: "100vh",
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <img height="400px" width="400px" alt="Russia Image" src={process.env.PUBLIC_URL + "/dist/img/empty.png"} />
                        <p className={css.producttitle}>Бүртгэлтэй бараа байхгүй байна</p>
                    </div >
                    :
                    <div>
                        <center className={css.center}>
                            <SearchBox searchField={searchField} onSearch={onSearchChanged} ></SearchBox>
                            <div className={css.grPQmX}>
                                {/* {
                                    searchField.length > 0 ? filteredProducts.map(item => (
                                        <ProductItem key={item.id} product={item}></ProductItem>
                                    )) : currentProducts.map(item => (
                                        <ProductItem key={item.id} product={item}></ProductItem>
                                    ))
                                } */}
                                {
                                    filteredProducts.map(item => (
                                        <ProductItem key={item.id} product={item}></ProductItem>
                                    ))

                                }
                            </div>
                            {/* <Pagination
                                productsPerpage={productsPerpage}
                                paginate={paginate}
                                totalProducts={products.length}
                            /> */}
                        </center>
                    </div>
    );
};
export default withRouter(ProductList);