import React from 'react';
import css from './style.module.css'
import ContactNumber from '../../components/user/Component/CheckoutDetailPop'
import {ProfileDetail} from '../../components/user/Component/ProfileDetail'
import CheckoutAddAddress from '../../components/user/Component/CheckoutAddAddress'
import CheckoutAddNumber from '../../components/user/Component/CheckoutAddNumber'
import { validation } from '../../util/Validator'
import firebase from '../../firebase'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {UserContext} from '../../components/context/UserContext'
import { stat } from 'fs';

const Index =()=> {
    const defaultData = {
        data: {
            name: '',
            val: '',
            isupdate:false,
            key:'',
        },
        errorMsg: {
            name: { show: false },
            val: { show: false }
        }
    };
    const ctxUser = React.useContext(UserContext);
    const objuser = JSON.parse(ctxUser.state.user);
    // constructor(props){
    //     super(props);
    //     onChangeRadioValue = onChangeRadioValue.bind(this);
    //     handleInputChange = handleInputChange.bind(this);
    //     addAddress = addAddress.bind(this);
    //     addNumber = addNumber.bind(this);
    //     setAddData = setAddData.bind(this);
    //     setUpdateData = setUpdateData.bind(this);
    //     submitprofile = submitprofile.bind(this);
    //     state = {
    //         ...defaultData,
    //         user:null
    //     }
    // }
    const [state,setState] = React.useState({
        ...defaultData,
        user:null
    });
    React.useEffect(() => {
        // console.log('objuser:',objuser);
        firebase.firestore().collection('users')
        .where('email', '==', objuser.email).
        onSnapshot((collections) => {
            const users = collections.docs.map((product) => product.data());
            if(users.length>0){
                setState({
                    ...state, user:users[0]
                });
            }
            // console.log('users: ',users);
        })
    },[]);
    const onChangeRadioValue = (event) => {
        // let id = event.target.id.substr(event.target.id.length-1,1);
        // let val = event.target.value;
        // let name = 'sel'+event.target.id.substr(0,event.target.id.length-1);
        // // let deliveryname = 'delivery'+event.target.id.substr(0,event.target.id.length-1);
        // setState({
        //     [name]:id,
        //     // [deliveryname]:val,
        // });
    }
    const addAddress = () => {
        setState(state=>{
            const myaddresses = state.data.isupdate? 
                state.user.myaddresses.map((item, j) => {
                    if (j === state.data.key) {
                        return {name:state.data.name,val:state.data.val};
                    } else {
                        return item;
                    }
                }):
                state.user.myaddresses?[...state.user.myaddresses, {name:state.data.name,val:state.data.val}]:[{name:state.data.name,val:state.data.val}];
            return {...state,
                user:{
                    ...state.user,
                    myaddresses:myaddresses
                },
                data:defaultData.data
            };
        });
        // console.log('state.user:',state.user);
    }
    const addNumber = () => {
        setState(state=>{
            const mynumbers = state.data.isupdate?
                state.user.mynumbers.map((item, j)=>{
                    if(j === state.data.key){
                        return {name:state.data.name,val:state.data.val};
                    }
                    else{
                        return item;
                    }
                }):
                state.user.mynumbers?[...state.user.mynumbers, {name:state.data.name,val:state.data.val}]:[{name:state.data.name,val:state.data.val}];
            return {...state,
                user:{
                    ...state.user,
                    mynumbers:mynumbers
                },
                data:defaultData.data
            }
        });
        // console.log('state.user:',state.user);
    }
    const submitprofile = () => {
        confirmAlert({
            title: 'Баталгаажуулах',
            message: 'Та үүнийг хийхдээ итгэлтэй байна уу?',
            buttons: [
                {
                    label: 'Тийм',
                    onClick: () => {
                        firebase.firestore().collection('users')
                        .doc(state.user.id).set(state.user)
                        .then((ref) => {
                            alert("Амжилттай засагдлаа");
                        }).catch(ex => {
                            console.log("servertei holbogdhod " + ex);
                            alert("Мэдээлэл засхад алдаа гарлаа.");
                        }).finally(
                            
                        );
                    }
                },
                {
                    label: 'Үгүй',
                    onClick: () => {}
                }
            ]
        });
    }
    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const validate = validation(value); 
        
        let formValid = false;

        if (!validate.show) {
            let istemvavlid = true;
            Object.values(state.data).map((el, index) => {
                if (![2, 3].includes(index) && el.length == 0 && Object.keys(state.errorMsg)[index] != [name]) {
                    istemvavlid = false;
                    return;
                }
            });
            if (istemvavlid) {
                formValid = true;
            }
        }
        setState({...state,
            errorMsg: {
                ...state.errorMsg,
                [name]: validate
            },
            data: {
                ...state.data,
                [name]: value
            },
            formValid: formValid
        });
    }
    const setAddData = (opt) => {
        setState({ 
            ...state,
            data:{...defaultData.data,name:opt=='number'? 'Гар утас':''},
            formValid : false
        });
    }
    const setUpdateData = (data, key) => {
        setState({
            ...state,
            data:{...data, isupdate:true, key:key},
            formValid : true
        });
    }
    const delAddress = (key) => {
        state.user.myaddresses.splice(key, 1);
        setState({...state});
    }
    const delNumber = (key) => {
        state.user.mynumbers.splice(key, 1);
        setState({...state});
    }
    // console.log("login render", state)
    return (
        <div className={css.kqrKyZ}>
                <form>
                    <div className={css.dcTgel}>
                        <div className={css.hGqnHy}>
                            <div className={css.hwlTmd}>
                                <ContactNumber key='1' ispbutton={false} option={'address'} data={state.user && state.user.myaddresses ? state.user.myaddresses:[]} sel={0} onChangeRadioValue={onChangeRadioValue} setAdd={setAddData} setUpdateData={setUpdateData} delData={delAddress}></ContactNumber>
                                <ContactNumber key='2' ispbutton={false}  option={'number'}  data={state.user && state.user.mynumbers ? state.user.mynumbers:[]} sel={0} onChangeRadioValue={onChangeRadioValue} setAdd={setAddData} setUpdateData={setUpdateData} delData={delNumber}></ContactNumber>
                            </div>
                            <ProfileDetail user={state.user} submitprofile={submitprofile}></ProfileDetail> 
                        </div>
                    </div>
                </form>
                <CheckoutAddAddress data={state.data} formValid={state.formValid} handleInputChange={handleInputChange} addfunc={addAddress}></CheckoutAddAddress>
                <CheckoutAddNumber data={state.data} formValid={state.formValid} handleInputChange={handleInputChange} addfunc={addNumber}></CheckoutAddNumber>
            </div>
        )
    }

export default Index;