import React, { useContext, useState } from "react";
import { isConstructorDeclaration } from "typescript";
import { BasketContext } from '../../../context/BasketContext'
import ProductDetail from "../ProductDetail";
import css from './style.module.css'
import firebase from 'firebase'

export const ProductItem = (props) => {
  const ctx = useContext(BasketContext);
  const tproduct = { ...props.product, qty: 1 };
  let pos = ctx.basket.map((e) => { return e.id; }).indexOf(tproduct.id);
  const [isShow, setShow] = useState(false);
  const detailToggle = (iss) => {
    setShow(iss);
  }
  const addtofav = async (product) => {
        await firebase.firestore().collection("favs").doc(JSON.parse(localStorage.getItem('user')).id).get().then( async (doc) => {
          if (doc.exists) {
            var old = doc.data().products;
            if(old.find(all=>all.id == product.id)) {
              var filtered = old.filter(function(el) { return el.id != product.id; });
              await firebase.firestore().collection("favs").doc(JSON.parse(localStorage.getItem('user')).id).set({"products":filtered});
              alert("Хадгалахаа болилоо")
            } else {
              old.push(product);
            await firebase.firestore().collection("favs").doc(JSON.parse(localStorage.getItem('user')).id).set({"products":old});
            alert("Амжилттай хадгаллаа")
            }
          } else {
            const arr = [];
            arr.push(product);
            await firebase.firestore().collection("favs").doc(JSON.parse(localStorage.getItem('user')).id).set({"products":arr})
          }
      });


    
  }
  return (
    <div className={css.cxyAZX}>
      <div >
        <div  className={css.bLdNZu} style={{position:'relative'}}>
          <p style={{position:'absolute', top:'5px',right:'10px',fontSize:'26px'}} onClick={()=>addtofav(props.product)}><i class="fas fa-save" style={{color:'#009E7F',fontSize:'medium'}}></i></p>
          <img
          onClick={() => { detailToggle(true) }}
            className={css.ithsmr}
            src={props.product.image}
          ></img>
        </div>
        <div className={css.kKpnwf}>
        <h3 className={css.producttitle} onClick={() => { detailToggle(true) }}>Код: {props.product.id}</h3>
          <h3 className={css.producttitle} onClick={() => { detailToggle(true) }}>{props.product.name}</h3>
          <div className={css.productprices}>
            <div className={css.productpercentprice} style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
              <span className={css.productpercent}>
                {`15`}
                {`%`}
              </span>
              <span className={css.productprice} style={{fontSize:'medium'}}>{props.product.price}₮</span>
            </div>
            <div className={css.productpercentprice}>
              {/* <span className={css.productpercent}>
                {`23`}
                {`%`}
              </span>
              <span className={css.productprice}>{props.product.price1}₮</span> */}
            </div>
            {/* <div className={css.productpercentprice}>
              <span className={css.productpercent}>
                {`100`}
                {`%`}
              </span>
              <span className={css.productprice}>{props.product.price2}₮</span>
            </div> */}
          </div>
          <div className={css.productpointquantity}>
            {/* <span className={css.productquantity}>
              {props.product.quantity}
              {`ш`}
            </span> */}
            <span className={css.productpoint+' '+css.black} style={{fontSize:'small'}}>{props.product.point}оноо</span>
            {
              props.type == 'userbasket' ? <p style={{fontWeight:'bold',margin:'0'}}>{props.product.qty}ш</p> : <>{pos == -1 ?
                // props.product.quantity>0?
                <button className={css.byhaCB} onClick={() => { ctx.addToBasket(tproduct) }}>
                  <i className='fa fa-shopping-basket'></i>
                </button>
                // :
                // <button className={css.byhaCB} onClick={() => { alert('"'+props.product.name+'" барааны үлдэгдэл хүрэлцэхгүй байна') }}>
                //   <i className='fa fa-shopping-basket'></i>
                // </button>
                :
                <div className={css.hgBplF}>
                  <button className={css.jJUnxI} onClick={() => { ctx.removeFromBasket(tproduct) }}>
                    <i className="fa fa-minus"></i>
                  </button>
                  <span className={css.gSFvVa
                  // +' '+
                  // (props.product.quantity<ctx.basket[pos].qty && css.colorred)
                  }>{ctx.basket[pos].qty}</span>
                  {
                  //props.product.quantity>ctx.basket[pos].qty?
                  <button className={css.jJUnxI} onClick={() => { ctx.addToBasket(tproduct) }}>
                    <i className="fa fa-plus"></i>
                  </button>
                  // :
                  // <button className={css.jJUnxI} onClick={() => { alert('"'+props.product.name+'" барааны үлдэгдэл хүрэлцэхгүй байна')}}>
                  //   <i className="fa fa-plus"></i>
                  // </button>
                  }
                </div>
              }</>
            }
          </div>
        </div>
      </div>
      {isShow && <ProductDetail setShow={detailToggle} product={props.product}></ProductDetail>}
    </div>
  );
}
