import React, { Component } from 'react'
import css from './style.module.css'
import firebase from "../../../../firebase";
import { validation } from '../../../../util/Validator'
class Exchangee extends Component {
    defaultStatus = {
        loading: false,
        formValid: false,
        exchange: {
            code: "RUB",
            last_date: new Date().toString(),
            name: "ОХУ-ын рубль",
            rate: "",
        },
        errorMsg: {
            code: {show: false},
            last_date: {show: false},
            name: {show: false},
            rate: {show: false},
        }
    };

    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.state = {
            ...this.defaultStatus
        }
    }

    getExchangeData() {
        firebase.firestore().collection("exchange")
            .get().then(querySnapshot => {
                this.setState({ loading: true });
                let exchangeData = null;
                querySnapshot.forEach(function (doc) {
                    exchangeData = doc.data();
                });
                if(exchangeData){
                    console.log('excha:',exchangeData)
                    this.setState({ exchange: {...exchangeData} });
                    this.setState({ loading: false })
                }
            });
    }

    exchangeUpdate() {
        this.setState({ loading: true });
        this.state.exchange.last_date = new Date().toString();
        firebase.firestore().collection('exchange').doc(this.state.exchange.code).set(this.state.exchange).then((ref) => {
            alert("амжилттай заслаа");
        }).catch(ex => {
            console.log("servertei holbogdhod " + ex);
            alert("сервертэй холбогдоход алдаа гарлаа", ex);
        }).finally(
            this.getExchangeData()
        );
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const type = target.type;
        const validationTypes = {
            price: 'POSITIVENUMBER',
            id: 'EQUAL'
        }
        const validate = validation(value, validationTypes[name]);

        let formValid = false;

        if (!validate.show) {
            let istemvavlid = true;
            Object.values(this.state.exchange).map((el, index) => {
                if (el.length == 0 && Object.keys(this.state.exchange)[index] != [name]) {
                    istemvavlid = false;
                    return;
                }
            })
            if (istemvavlid) {
                formValid = true;
            }
        }
        this.setState({
            errorMsg: {
                ...this.state.errorMsg,
                [name]: validate
            },
            exchange: {
                ...this.state.exchange,
                [name]: value
            },
            formValid: formValid
        });
    }

    componentDidMount() {
        this.getExchangeData();
    }

    render(){
        return(
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            {!this.state.isloading?
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="code">Ханш код:</label>
                                    <input disabled name='code' id='code' value={this.state.exchange.code} type="text" onChange={(event) => { this.handleInputChange(event) }} className="form-control" placeholder="код" autoComplete="off" />
                                    <p className={css.errorMsg}>{this.state.errorMsg.code.show && this.state.errorMsg.code.message}</p>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="name">Ханш нэр:</label>
                                    <input name='name' id='name' value={this.state.exchange.name} type="text" onChange={(event) => { this.handleInputChange(event) }} className="form-control" placeholder="нэр" autoComplete="off" />
                                    <p className={css.errorMsg}>{this.state.errorMsg.name.show && this.state.errorMsg.name.message}</p>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="last_date">Ханш сүүлд шинэчилсэн:</label>
                                    <input disabled name='last_date' id='last_date' value={this.state.exchange.last_date} type="text" onChange={(event) => { this.handleInputChange(event) }} className="form-control" placeholder="сүүлд шинэчилсэн" autoComplete="off" />
                                    <p className={css.errorMsg}>{this.state.errorMsg.last_date.show && this.state.errorMsg.last_date.message}</p>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="rate">Ханш төгрөг:</label>
                                    <input name='rate' id='rate' value={this.state.exchange.rate} type="number" onChange={(event) => { this.handleInputChange(event) }} className="form-control" placeholder="Ханш төгрөг" autoComplete="off" />
                                    <p className={css.errorMsg}>{this.state.errorMsg.rate.show && this.state.errorMsg.rate.message}</p>
                                </div>
                                <button type="button" onClick={()=>{this.exchangeUpdate()}} disabled={this.state.formValid ? "" : "disabled"} className="btn btn-success">Хадгалах</button>
                            </div>
                            :<div className={css.loader}>
                            </div>}
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default Exchangee