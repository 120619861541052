import React, { useState } from 'react'
import axios from 'axios'
import firebase from '../../firebase'
const localExchangeKey = 'exchange';
const initialState = {
    loading: true,
    exchange: {
        done: false,
        data: {
            code: "RUB",
            last_date: "",
            name: "ОХУ-ын рубль",
            rate: "",
            rate_float: "",
        }
    }
}
export const ExchangeContext = React.createContext();
export const ExchangeProvider = (props) => {

    const [state, setState] = useState(initialState);
    const getExchange = (() => {
        firebase.firestore().collection("exchange")
            .get().then(querySnapshot => {
                let exchangeData = null;
                querySnapshot.forEach(function (doc) {
                    exchangeData = doc.data();
                });
                if(exchangeData){
                    setState({ ...state, exchange: { done: true, data: exchangeData }, loading: false });
                }
            });
            // гараас авсан утга харуулахаар болсон тул коммент болгов
        // axios.get("https://onlineshop-29d11-default-rtdb.firebaseio.com/exchange.json").then(res => {
        //     // console.log("res", res);
        //     if (res.data != null) {
        //         const lastUpdatedData = res.data.last_date;
        //         const date1 = new Date(lastUpdatedData);
        //         const date2 = new Date();
        //         const diffTime = Math.abs(date2 - date1);
        //         const diffDays = Math.round(diffTime / (1000 * 60 * 60));
        //         // console.log("dif", diffDays);
        //         if (diffDays > 5) {
        //             getKhaanBankRub();
        //         } else {
        //             setState({ ...state, exchange: { done: true, data: res.data }, loading: false });
        //         }
        //     } else {
        //         getKhaanBankRub();
        //     }
        // });
    });

    const getKhaanBankRub = (() => {
        const proxyurl = "https://thingproxy.freeboard.io/fetch/";
        axios.get(
            proxyurl + "http://www.khanbank.com/mn/home/ratesForSites/", {
            headers: { "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Headers": "Origin", "Content-Type": "X-Auth-Token" }
        }
        ).then((response) => {
            const trTags = response.data.substr(response.data.indexOf("<tr>"));
            const data = {
                code: "RUB",
                last_date: new Date().toString(),
                name: "ОХУ-ын рубль",
                rate: "",
                rate_float: "",
            }
            trTags.split("<tr>").map((el, index) => {
                var isHaveRub = el.includes("RUB");
                if (isHaveRub) {
                    const tdRubgTags = el.split("<td>");
                    const tdRubgLastTag = tdRubgTags[tdRubgTags.length - 1];
                    let regex = /[+-]?\d+(\.\d+)?/g;
                    data.rate = tdRubgLastTag.match(regex)[0];
                    data.rate_float = tdRubgLastTag.match(regex)[0];
                }
            })
            // console.log("data", data);
            axios.put("https://onlineshop-29d11-default-rtdb.firebaseio.com/exchange.json", data).then(res => {
                // addLocalStore(localExchangeKey, JSON.stringify(data));
                setState({ ...state, exchange: { done: true, data: data }, loading: false });
            }).catch(ex => {
                console.log("ex" + ex);
                setState({ ...state, exchange: { done: true, data: data }, loading: false });

            })
        }).catch((ex) => {
            console.log('catch:', ex);
            setState({
                ...state, exchange: {
                    done: false, data: {
                        code: "RUB",
                        last_date: "",
                        name: "Ханш татхад алдаа гарлаа",
                        rate: "",
                        rate_float: "",
                    }
                }, loading: false
            });
        });
    });
    const addLocalStore = (id, value) => {
        localStorage.setItem(id, value);
    }

    const readFromLocalStore = (id) => {
        return id;
    }
    const removeAllLocalStore = () => {
    }
    return (
        <ExchangeContext.Provider value={{ state, getExchange }}>{props.children}</ExchangeContext.Provider>
    )
}
