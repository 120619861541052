import React from 'react'
import {OrderStatuses} from '../../../../util/OrderStatuses'

import css from './style.module.css'
export const MobileView = (props) =>{
    // console.log('propsmyorder:',props.myorder);
    let inmyorder = null;
    let checked0 = '';
    let checked1 = '';
    let checked2 = '';
    let checked3 = '';
    if(props.myorder){
        if(props.myorder.orderstatus == 3){
            checked3 = ' ' +css.checked2;
        }
        else if(props.myorder.orderstatus == 2){
            checked2 = ' ' +css.checked;
            checked1 = ' ' +css.checked;
            checked0 = ' ' +css.checked;
        }
        else if(props.myorder.orderstatus == 1){
            checked1 = ' ' +css.checked;
            checked0 = ' ' +css.checked;
        }
        else{
            checked0 = ' ' +css.checked;
        }
        inmyorder = props.myorder.orderdetail.map(el=>{
            props.orderProducts.forEach(itt=>{
                if(el.id == itt.id){
                    el.productimage = itt.image;
                    el.productname = itt.name;
                }
            })
            return el
        });
    }
    return(
        <div className={css.rccollapseitem + (props.active?' '+css.rccollapseitemactive:'')}>
            <div className={css.rccollapseheader} onClick={()=>{props.changeDetail(props.myorder.orderid,'mob')}}>
                <i className={css.arrow}></i>
                <div className={css.hzdtju}>
                    <div className={css.lihBKG}>
                        <span className={css.hjcFuj}>
                            {'Захиалга'}
                            <span className={css.font11}><br></br>{'#'}{props.numb}</span>
                        </span>
                        <span className={css.iTdekY}>
                            {props.myorder.orderstatusname}
                        </span>
                    </div>
                    <div className={css.guZSJO}>
                        <div className={css.Bmugg}>
                            {'Захиалсан өдөр'}{':'}
                            <span>{props.myorder.orderdate}</span>
                        </div>
                        <div className={css.Bmugg+' '+css.price}>
                            {'Нийт оноо'}{':'}
                            <span>{props.myorder.totalpoint}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={css.rccollapsecontent + (!props.active? ' '+css.rccollapsecontentinactive:'')}>
                <div className={css.rccollapsecontentbox}>
                    <div className={css.bmogfF}>
                        <div className={css.jjWgRb+' '+css.eUHqXZ}>
                            <div className={css.bLiRNB+' '+css.cwZtQI}>
                                <h3>Хүргэх хаяг</h3>
                                <span className={css.fbmTwo}>{props.myorder.deliveryaddress}</span>
                            </div>
                            <div className={css.bPJmao+' '+css.fgcIfk}>
                                <div className={css.cqjOZT}>
                                    {'Үнэ15%'}
                                    <div className={css.cFTfrZ}>
                                        {props.myorder.totalprice}
                                        ₮
                                    </div>
                                </div>
                                <div className={css.cqjOZT}>
                                    {/* {'Үнэ23%'}
                                    <div className={css.cFTfrZ}>
                                        {props.myorder.totalprice1}
                                        ₮
                                    </div> */}
                                </div>
                                {/* <div className={css.cqjOZT}>
                                    {'Үнэ100%'}
                                    <div className={css.cFTfrZ}>
                                        {props.myorder.totalprice2}
                                        ₮
                                    </div>
                                </div> */}
                                <div className={css.cqjOZT +' '+css.grandTotal}>
                                    {'Нийт оноо'}
                                    <div className={css.cFTfrZ}>
                                        {props.myorder.totalpoint}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={css.lbTChn+' '+css.hALMhu}>
                            <div className={css.foKLRz}>
                                <div className={css.jYdTsb}>
                                    <div className={css.flwWPp+' '+checked0}>
                                        {checked0==''?'1':
                                        <span className={css.fRVGLf}>
                                            <i className='fa fa-check'></i>
                                        </span>
                                        }
                                    </div>
                                    <div className={css.progressboxstyle+' '+css.wHIWv+' '+checked0}></div>
                                </div>
                                <div className={css.kNirdD}>
                                    <span className={css.fcCkVG}>
                                        {OrderStatuses(0).name}
                                    </span>
                                </div>
                            </div>
                            <div className={css.foKLRz}>
                                <div className={css.jYdTsb}>
                                    <div className={css.flwWPp+' '+checked1}>
                                        {checked1==''?'2':
                                        <span className={css.fRVGLf}>
                                            <i className='fa fa-check'></i>
                                        </span>
                                        }
                                    </div>
                                    <div className={css.progressboxstyle+' '+css.wHIWv+' '+checked1}></div>
                                </div>
                                <div className={css.kNirdD}>
                                    <span className={css.fcCkVG}>
                                        {OrderStatuses(1).name}
                                    </span>
                                </div>
                            </div>
                            <div className={css.foKLRz}>
                                <div className={css.jYdTsb}>
                                    <div className={css.flwWPp+' '+checked2}>
                                        {checked2==''?'3':
                                        <span className={css.fRVGLf}>
                                            <i className='fa fa-check'></i>
                                        </span>
                                        }
                                    </div>
                                    <div className={css.progressboxstyle+' '+css.wHIWv+' '+checked2}></div>
                                </div>
                                <div className={css.kNirdD}>
                                    <span className={css.fcCkVG}>
                                        {OrderStatuses(2).name}
                                    </span>
                                </div>
                            </div>
                            <div className={css.foKLRz}>
                                <div className={css.jYdTsb}>
                                    <div className={css.flwWPp+' '+checked3}>
                                        {checked3==''?'0':
                                        <span className={css.fRVGLf}>
                                            <i className='fa fa-check'></i>
                                        </span>
                                        }
                                    </div>
                                    <div className={css.progressboxstyle+' '+css.wHIWv+' '+checked3}></div>
                                </div>
                                <div className={css.kNirdD}>
                                    <span className={css.fcCkVG}>
                                        {OrderStatuses(3).name}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={css.rctable}>
                            <table className={css.cAgIfa}>
                                <thead>
                                    <tr>
                                        <th>Бараа</th>
                                        <th>Тоо ширхэг</th>
                                        <th>нэгж үнэ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {inmyorder.map((item,key)=>(
                                        <tr key={'inmyorder'+key} data-row-key={key+1}>
                                            <td>
                                                <span className={css.lhXkXj}>
                                                    
                                                    <span className={css.iVTGVp}>
                                                        <span className={css.kIgOkd}>{item.name}</span>
                                                        {/* <span className={css.bnnPwi}>2lb</span> */}
                                                        <span className={css.kcpNyZ}>
                                                            {item.price}₮
                                                        </span>
                                                        <span className={css.kcpNyZ}>
                                                            {item.price1}₮
                                                        </span>
                                                        {/* <span className={css.kcpNyZ}>
                                                            {item.price2}₮
                                                        </span> */}
                                                    </span>
                                                </span>
                                            </td>
                                            <td>{item.quantity}</td>
                                            <td className={css.tdright}>
                                                <p>{item.price*item.quantity}₮</p>
                                                <p>{item.price1*item.quantity}₮</p>
                                                {/* <p>{item.price2*item.quantity}₮</p> */}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}