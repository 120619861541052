import React, { Component } from 'react'
import UserDataTable from '../../DataTable/users/UserDataTable'
export default class UserContent extends Component {
    
    render() {
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <UserDataTable />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
