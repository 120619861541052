import React, { Component } from 'react'
import firebase from '../../../../..//firebase'
import axios from '../../../../../axios'
import css from './style.module.css'
export default class index extends Component {

    getTypeText(typeId) {
        let typeText = '';
        this.props.productTypes.map(el => {
            if (typeId === el.id) {
                typeText = el.name;
            }
        })
        return typeText;
    }
    render() {
        // console.log("render shaaj bnuu")
        return (
            this.props.isLoading == true ? <div className={css.loader}></div> : <div className="card">
                <div className="card-header">
                    <h3 className="card-title">Төрөлийн жагсаалт</h3>
                </div>
                <div className="card-body" >
                    <table id="typeTable" className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                {/* <th></th> */}
                                <th>Дугаар</th>
                                <th>Нэр</th>
                                <th>Тайлбар</th>
                                <th>Эрэмбэ</th>
                                <th></th>
                                {/* <th></th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.productDatas.map((el, index) => {
                                return (<tr key={el.id}>
                                    {/* <td className={css.CardBodyImgTd}><img className={css.CardBodyImg} src={el.image || "http://via.placeholder.com/50"} alt="firebase-image" /></td> */}
                                    <td>{el.id}</td>
                                    <td>{el.name}</td>
                                    <td>{el.description}</td>
                                    <td>{el.sorting}</td>
                                    <td className={css.CardBodyEdit}><button id={css.CardIconCenter}
                                        data-toggle="modal" onClick={() => { this.props.setProductType(el, true, this.props.productDatas[index]) }} data-target="#modal-default" className="btn btn-success"><i className="fas fa-edit"></i></button>
                                    </td>
                                    {/* <td className={css.CardBodyEdit}><button id={css.CardIconCenter} className="btn btn-danger"><i className="fas fa-trash-alt"></i></button></td> */}
                                </tr>)
                            })}
                        </tbody>
                        <tfoot>
                            <tr>
                                {/* <th></th> */}
                                <th>Дугаар</th>
                                <th>Нэр</th>
                                <th>Тайлбар</th>
                                <th>Эрэмбэ</th>
                                <th></th>
                                {/* <th></th> */}
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>

        )
    }
}
