import React, { Component } from 'react'
import css from './style.module.css'
import firebase from "../../../../firebase";
import Resizer from 'react-image-file-resizer';
class Page2 extends Component {
    defaultStatus = {
        loading: false,
        formValid: false,
        page2: {
            id: null,
            image: null,
            modifedDate: null,
        }
    };

    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.state = {
            ...this.defaultStatus
        }
    }

    getPage2Data() {
        firebase.firestore().collection("page2")
            .get().then(querySnapshot => {
                this.setState({ loading: true });
                let page2Data = null;
                querySnapshot.forEach(function (doc) {
                    page2Data = doc.data();
                });
                if (page2Data) {
                    console.log('excha:', page2Data)
                    this.setState({ page2: { ...page2Data } });
                    this.setState({ loading: false })
                }
            });
    }

    page2DataUpdate() {
        this.setState({ loading: true });
        this.state.page2.modifedDate = new Date().toString();
        firebase.firestore().collection('page2').doc(this.state.page2.id).set(this.state.page2).then((ref) => {
            alert("амжилттай заслаа");
        }).catch(ex => {
            console.log("servertei holbogdhod " + ex);
            alert("сервертэй холбогдоход алдаа гарлаа", ex);
        }).finally(
            this.getPage2Data()
        );
    }

    handleInputChange(event) {
        const target = event.target;
        const type = target.type;
        if (type == 'file') {
            if (event.target.files[0]) {
                Resizer.imageFileResizer(
                    event.target.files[0],
                    700,
                    600,
                    'png',
                    100,
                    0,
                    uri => {
                        this.setState({
                            page2: {
                                ...this.state.page2,
                                image: uri
                            },
                            formValid: true
                        });
                    },
                    'base64',
                    300,
                    400,
                );

            }
        }
    }

    componentDidMount() {
        this.getPage2Data();
    }

    render() {
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">

                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            {!this.state.loading ?
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="last_date">Сүүлд шинэчилсэн:</label>
                                        <input disabled name='last_date' id='last_date' value={this.state.page2.modifedDate} type="text" className="form-control" placeholder="сүүлд шинэчилсэн" autoComplete="off" />

                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputFile">Page 2 зураг:</label>
                                        <div className="input-group">
                                            <img className={css.inputImage} src={this.state.page2.image || "http://via.placeholder.com/20"} placeholder='Өмнөх зураг' alt='Өмнөх зураг' />
                                        </div>

                                        <div className="input-group">
                                            <div className="custom-file">
                                                <input type="file" name="image" onChange={(event) => { this.handleInputChange(event) }} id="exampleInputFile" />
                                            </div>

                                        </div>
                                    </div>
                                    <button type="button" onClick={() => { this.page2DataUpdate() }} disabled={this.state.formValid ? "" : "disabled"} className="btn btn-success">Хадгалах</button>
                                </div>
                                : <div className={css.loader}>
                                </div>}
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default Page2