import React from 'react'

import { NavLink } from 'react-router-dom'
import css from './style.module.css'
export default function index(props) {
    return (
        <div className={css.mainDivReg}>
            <div className="col-md-12 text-center">
                <h1>Бүртгүүлэх</h1>
            </div>
            {/* <div className="form-group">
                <input name='phoneNumber' id='exampleInputId' type="name" className="form-control" placeholder="Утасны дугаар" autoComplete="off" onChange={(event) => { props.handleInputChange(event) }} />
            </div>
            <p className={css.errorMsg}>{props.errorMsg.phoneNumber.show && props.errorMsg.phoneNumber.message}</p>
            <div className="form-group">
                <input name='password' id='exampleInputId' type="password" className="form-control" placeholder="Нууц үг" autoComplete="off" onChange={(event) => { props.handleInputChange(event) }} />
            </div>
            <p className={css.errorMsg}>{props.errorMsg.password.show && props.errorMsg.password.message}</p> */}

            {/* <p className={css.errorMsg}>{props.firebaseError}</p> */}
            {/* <div className={css.formInput}>
                <label>
                    <input name='phoneNumber' maxLength="8" id='exampleInputId' type="text" className="formControl" autoComplete="off" onChange={(event) => { props.handleInputChange(event) }} required />
                    <span className={css.placeholder}>Утасны дугаар</span>
                </label>
                <p className={css.errorMsg}>{props.errorMsg.phoneNumber.show && props.errorMsg.phoneNumber.message}</p>
            </div>
            <div className={css.formInput}>
                <label>
                    <input name='password' id='exampleInputId' type="password" className="formControl" autoComplete="off" onChange={(event) => { props.handleInputChange(event) }} required />
                    <span className={css.placeholder}>Нууц үг</span>
                </label>
                <p className={css.errorMsg}>{props.errorMsg.password.show && props.errorMsg.password.message}</p>
            </div>
            <div className={css.formInput}>
                <label>
                    <input name='confPassword' id='exampleInputId' type="password" className="formControl" autoComplete="off" onChange={(event) => { props.handleInputChange(event) }} required />
                    <span className={css.placeholder}>Нууц үг давтах</span>
                </label>
                <p className={css.errorMsg}>{props.errorMsg.confPassword.show && props.errorMsg.confPassword.message}</p>
            </div> */}

            <div className="form-group">
                <input name='email' type="email" className="form-control" placeholder="email хаяг" autoComplete="off" onChange={(event) => { props.handleInputChange(event) }} />
                <p className={css.errorMsg}>{props.errorMsg.email.show && props.errorMsg.email.message}</p>
            </div>
            <div className="form-group">
                <input name='phoneNumber' id='exampleInputId' type="number" className="form-control" placeholder="Утасны дугаар" autoComplete="off" onChange={(event) => { props.handleInputChange(event) }} />
                <p className={css.errorMsg}>{props.errorMsg.phoneNumber.show && props.errorMsg.phoneNumber.message}</p>
            </div>
            <div className="form-group">
                <input name='password' id='exampleInputId' type="password" className="form-control" placeholder="Нууц үг" autoComplete="off" onChange={(event) => { props.handleInputChange(event) }} />
            </div>
            <p className={css.errorMsg}>{props.errorMsg.password.show && props.errorMsg.password.message}</p>
            <div className="form-group">
                <input name='confPassword' id='exampleInputId' type="password" className="form-control" placeholder="Нууц үг давтах" autoComplete="off" onChange={(event) => { props.handleInputChange(event) }} />
            </div>
            <p className={css.errorMsg}>{props.errorMsg.confPassword.show && props.errorMsg.confPassword.message}</p>
            <button type="submit" onClick={props.submitData} style={{ backgroundColor: "#009e7f" }} className="btn btn-success" disabled={props.formValid ? "" : "disabled"}>Бүртгүүлэх</button>
            <div style={{ color: "red" }}>{props.firebaseError}</div>
            <div style={{ marginTop: "5%" }}>
                <p className="text-center">Бүртгэлтэй юу?
                <NavLink to="/login" id="signup">Нэвтрэх</NavLink></p>
            </div>
        </div >
    )
}
