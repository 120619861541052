import React, { Component } from 'react'
import firebase from '../../../../firebase'
import axios from '../../../../axios'
import css from './style.module.css'
import tableName from './../../../../util/TableNames'
export default class ProductDataTable extends Component {

    getTypeText(typeId) {
        let typeText = '';
        this.props.productTypes.map(el => {
            if (typeId == el.id) {
                typeText = el.name;
            }
        })
        return typeText;
    }

    render() {
        const z = JSON.parse(this.props.user)
        console.log();
        // console.log("product datatable ")
        return (
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">Барааны жагсаалт</h3>
                </div>
                <div className="card-body" >
                    <table id="example1" className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                {/* <th></th> */}
                                <th></th>
                                <th>Дугаар</th>
                                <th>Нэр</th>
                                <th>Төрөл</th>
                                <th>Үнэ</th>
                                <th>Оноо</th>
                                <th></th>
                                {/* <th></th> */}
                                {z.phoneNumber == "admin01" ?null :<th style={{ 'display': 'block' }}></th>}
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.productDatas.map((el, index) => {
                                return [<tr key={index}>
                                    {/* <td className="details-control"></td> */}
                                    <td className={css.CardBodyImgTd}><img className={css.CardBodyImg} src={el.image || "http://via.placeholder.com/50"} alt="firebase-image" /></td>
                                    <td>{el.id}</td>
                                    <td>{el.name}</td>
                                    <td>{this.getTypeText(el.type)}</td>
                                    <td>{el.price}</td>
                                    <td>{el.point}</td>
                                    {z.phoneNumber == "admin01" ?null : <td className={css.CardBodyEdit}><button id={css.CardIconCenter} test="bzy"
                                        data-toggle="modal" onClick={() => { this.props.setProduct(el, true, this.props.productDatas[index]) }} data-target="#modal-default" className="btn btn-success"><i className="fas fa-edit"></i></button>
                                    </td>}
                                    <td className={css.CardBodyEdit}><button onClick={() => { this.props.deleteProduct(el.id) }} id={css.CardIconCenter} className="btn btn-danger"><i className="fas fa-trash-alt"></i></button></td>
                                    {/* <td>
                                        <div id={"hidden-control" + el.id} style={{ 'display': 'none ' }}>
                                            <p>{el.id} <button>sadas</button></p>
                                            <p>{el.price}<button>sadass</button></p>
                                            <p>{el.point}<button>sadas</button></p>
                                        </div>
                                    </td> */}
                                </tr>]
                            })}
                        </tbody>

                        <tfoot>
                            <tr>
                                {/* <th></th> */}
                                <th></th>
                                <th>Дугаар</th>
                                <th>Нэр</th>
                                <th>Төрөл</th>
                                <th>Үнэ</th>
                                <th>Оноо</th>
                                <th></th>
                                {/* <th></th> */}
                                {z.phoneNumber == "admin01" ?null :<th style={{ display: "block" }}></th>}
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>

        )
    }
}
