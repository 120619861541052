const CourseList = ({ courses }) => {
  console.log("color: #007acc;", courses);
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-6">Сургалтууд</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {courses.map((course) => (
          <div key={course.id} className="border p-4 rounded">
            <h3 className="font-bold">{course.title}</h3>
            <div
              className="mt-4 embed-container"
              dangerouslySetInnerHTML={{ __html: course.embedContent }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CourseList;
