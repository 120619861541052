import React, { Component } from 'react'
import DataTable from '../../DataTable/products/ProductsDataTable'
import ProductControl from '../../components/ProductContol'
import StatusComponent from '../../components/StatusComponent/index'

import $ from 'jquery'
import Resizer from 'react-image-file-resizer';
import { validation } from '../../../../util/Validator'
import firebase from "./../../../../firebase";
import xlsxFile from 'read-excel-file'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import css2 from '../../components/ProductContol/style.module.css'

import css from '../../DataTable/products/style.module.css'
class ProductContent extends React.Component {
    defaultStatus = {
        productLoading: false,
        excelImpLoading: false,
        isUpdate: false,
        mainimage: '',
        product: {
            id: '',
            name: '',
            type: '',
            price: '',
            price1: '',
            // price2: '',
            point: '',
            quantity: 0,
            image: '',
            specialDescription: '',
            goodDescription: '',
            bodyDescription: '',
            description: ''
        },
        errorMsg: {

            id: { show: false },
            name: { show: false },
            price: { show: false },
            price1: { show: false },
            // price2: { show: false },
            point: { show: false },
            quantity: { show: false },
            image: { show: false },
            specialDescription: { show: false },
            goodDescription: { show: false },
            bodyDescription: { show: false },
            description: { show: false },
            type: { show: false },
        }
    };
    tempProduct = {};
    // Барааны үлдэгдэл өөрчлөхөд ашиглана
    quantityProd = [];
    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.productAction = this.productAction.bind(this);
        this.setUpdateProduct = this.setUpdateProduct.bind(this);
        this.setAddProduct = this.setAddProduct.bind(this);
        this.deleteProduct = this.deleteProduct.bind(this);
        this.fileHandler = this.fileHandler.bind(this);
        this.state = {
            ...this.defaultStatus,
            productDatas: [],
            tableLoading: true,
            productTypes: [{
                id: '',
                name: '',
                image: '',
                description: '',
                specialDescription: '',
                goodDescription: '',
                bodyDescription: '',
            }],
        }
    }
    productAdd(product, e) {
        this.setState({ productLoading: true });
        firebase.firestore().collection('product').doc(product.id).set(product).then((ref) => {
            alert(this.state.isUpdate ? "амжилттай заслаа" : "амжилттай нэмлээ");
            if (this.state.isUpdate) {
                $('#exit').click();
            }
        }).catch(ex => {
            console.log("servertei holbogdhod " + ex);
            alert("servertei hobogdhod aldaa garlaa", ex);
        }).finally(
            this.setState({
                product: {
                    ...this.defaultStatus.product,
                },
                productLoading: false,
            }),
            this.getProductDatas()
        );
    }
    deleteProduct(id) {
        this.setState({ tableLoading: true });
        firebase.firestore().collection("product").doc(id).delete().then((res) => {
        }).catch(ex => {
            console.log(ex)
            alert("servertei holbogdhod aldaa garlaa" + ex)
        }).finally(
            this.getProductDatas()
        )
    }


    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const type = target.type;
        const validationTypes = {
            price: 'POSITIVENUMBER',
            id: 'EQUAL'
        }
        if (type == 'file') {
            if (event.target.files[0]) {
                Resizer.imageFileResizer(
                    event.target.files[0],
                    500,
                    500,
                    'png',
                    100,
                    0,
                    uri => {
                        // console.log(uri.split(',')[1])
                        this.setState({ mainimage: uri });

                    },
                    'base64',
                    200,
                    200,
                );

            }
        }
        const validate = validation(value, validationTypes[name], name == 'id' && this.state.productDatas);

        let formValid = false;

        if (!validate.show) {
            let istemvavlid = true;
            Object.values(this.state.product).map((el, index) => {
                if (el.length == 0 && Object.keys(this.state.product)[index] != [name]) {
                    istemvavlid = false;
                    return;
                }
            })
            if (istemvavlid) {
                formValid = true;
            }
        }
        this.setState({
            errorMsg: {
                ...this.state.errorMsg,
                [name]: validate
            },
            product: {
                ...this.state.product,
                [name]: value
            },
            formValid: formValid
        });
    }

    productAction = () => {
        this.setState({ productLoading: true });
        if (!this.state.mainimage && this.state.isUpdate) {
            this.productAdd(this.state.product);
        }
        else {
            firebase.storage().ref(this.state.product.id).putString(this.state.mainimage.split(',')[1], `base64`, { contentType: `image/png` }).then(responce => {
                responce.ref.getDownloadURL().then(downloadURL => {
                    this.setState({
                        product: {
                            ...this.state.product,
                            image: downloadURL
                        }
                    })
                    this.productAdd(this.state.product);

                });
            });
        }
    };
    setUpdateProduct(updatedProduct, isUpdate) {
        this.setState({ productLoading: true });
        firebase.firestore().collection('product')
            .doc(String(updatedProduct.id)).get()
            .then((res) => {
                let product = res.data();
                this.tempProduct = product;
                this.setState({
                    ...this.defaultStatus,
                    mainimage: null,
                    formValid: true,
                    isUpdate: isUpdate,
                    product: product,
                });
            })
    }
    setAddProduct() {
        this.setState({ ...this.defaultStatus, formValid: false });
    }
    refreshProduct = () => {
        document.getElementById("exampleInputFile").value = '';
        this.setState({
            product: this.tempProduct,
            mainimage: ''
        });
    }

    getProductTypes() {
        firebase.firestore().collection("productType")
            .get().then(querySnapshot => {
                const productTypeDatas = [];
                querySnapshot.forEach(function (doc) {
                    // console.log(doc.id, " => ", doc.data());
                    if (doc.data() != null)
                        productTypeDatas.push(doc.data())
                });
                this.setState({
                    productTypes: productTypeDatas
                });
            });
    }
    getProductDatas() {
        firebase.firestore().collection("product")
            .get().then(querySnapshot => {
                this.setState({ tableLoading: true });
                const productDatas = [];
                querySnapshot.forEach(function (doc) {
                    productDatas.push(doc.data())
                });
                // console.log("Datas=>", productDatas);
                if (document.getElementById("datatableId"))
                    document.getElementById("datatableId").remove()
                const script = document.createElement("script");

                script.setAttribute('id', "datatableId");
                script.src = "../js/content_datatable.js";
                script.async = true;
                document.body.appendChild(script);
                this.setState({ productDatas: productDatas });
                this.setState({ tableLoading: false })

            });
    }
    // алхам 1
    fileHandler(event) {
        let fileObj = event.target.files[0];
        let notUpdateProducts = [];
        let updateProducts = [];
        let duplicatelen = 0;
        let duplicateid = '[';
        //status shalgah
        xlsxFile(fileObj).then((rows) => {
            for (let i in rows) {
                let existProduct = this.state.productDatas.filter(obj => String(obj.id) == String(rows[i][0]));
                if (existProduct.length == 0) {
                    notUpdateProducts.push({ rownum: i + 1, data: rows[i] });
                } else {
                    let isnew = true;
                    updateProducts.map((item, key) => {
                        if (rows[i][0] === item[0]) {
                            if (duplicatelen < 3)
                                duplicateid += item[0] + ',';
                            duplicatelen++;
                            isnew = false;
                            updateProducts[key][1] = parseInt(item[1]) + parseInt(rows[i][1]);
                        }
                    });
                    if (isnew)
                        updateProducts.push(rows[i]);
                }
            }
            if (notUpdateProducts.length > 0) {
                //ene deer update oroogui nohduudiig ustgana.
            }
            if (updateProducts.length > 0) {
                let alllen = rows.length;
                let uplen = updateProducts.length;
                let notuplen = notUpdateProducts.length;
                confirmAlert({
                    title: 'Барааны тоо ширхэг нэмэх',
                    message: 'Excel-ийн нийт оруулсан ' + alllen + ' бичлэгээс' + (notuplen > 0 ? ', агуулахад бүртгэлгүй барааны тоо - "' + notuplen + 'ш"' : '') + ' агуулахад бүртгэлтэй барааны тоо - "' + uplen + '"ш' + (duplicatelen > 0 ? ' (давхардсан кодтой бичлэгийн тоо - "' + duplicatelen + 'ш" давхардсан бичлэгүүдийн кодууд - ' + duplicateid + '])' : '') + ' байна. Бүртгэлтэй "' + uplen + '"  бараануудын агуулах дахь үлдэгдэлийг өөрчлөх үү?',
                    buttons: [
                        {
                            label: 'Импорт хийх',
                            onClick: () => {
                                this.setState({ excelImpLoading: true });
                                this.quantityProd = updateProducts;
                                // console.log('this.quantityProd',this.quantityProd);
                                this.checkOrderStatus();
                            }
                        },
                        {
                            label: 'Үгүй',
                            onClick: () => {
                                alert('Тоо ширхэг нэмэх үйлдэл цуцлагдлаа');
                            }
                        }
                    ]
                });
            }
            else {
                alert('Импорт хийсэн excel файлд агуулахад бүртгэлтэй бараа байхгүй байна');
            }
        })
    }

    // 2-р алхам Захиалга хийж болох эсхийг шалгах
    checkOrderStatus = () => {
        let orderstatus = null;
        firebase.firestore().collection("orderstatus")
            .get()
            .then(querySnapshot => {
                querySnapshot.forEach(function (doc) {
                    if (doc.data() != null) {
                        orderstatus = doc.data();
                    }
                });
                // console.log('orderstatus: ',orderstatus);
                if (orderstatus) {
                    if (orderstatus.runing) {
                        const lastUpdatedData = orderstatus.lastdate;
                        const date1 = new Date(lastUpdatedData);
                        const date2 = new Date();
                        const diffTime = Math.abs(date2 - date1);
                        const diffSec = Math.round(diffTime / (1000));
                        // console.log("sec", diffSec);
                        if (diffSec < 5) {
                            alert('Та түр хүлээгээд дахин оролдоно уу');
                            return false;
                        }
                        else {
                            return this.setOrderStatus({ runing: true, userno: 'admin', lastdate: new Date().toString() }, 3)
                        }
                    }
                    else {
                        return this.setOrderStatus({ runing: true, userno: 'admin', lastdate: new Date().toString() }, 3)
                    }
                }
                else {
                    return this.setOrderStatus({ runing: true, userno: 'admin', lastdate: new Date().toString() }, 3)
                }
            })
            .catch((ex) => {
                console.log("firebase error: alkham2 " + ex);
                alert("Барааны үлдэгдэл өөрчлөхөд алдаа гарлаа. Алхам 2");
                this.setState({ excelImpLoading: false });
                return false;
            });
    }

    // 3-р алхам Захиалга хийж болно
    setOrderStatus = (orderstatus, alkham) => {
        // console.log('orderstatus:',orderstatus,alkham);
        firebase.firestore().collection('orderstatus').doc('1').set(orderstatus)
            .then(() => {
                if (alkham == 3) {
                    this.addQuantity();
                }
            })
            .catch(ex => {
                console.log("firebase error: alkham" + alkham + ' ' + ex);
                alert("Барааны үлдэгдэл өөрлөхөд алдаа гарлаа. Алхам 3");
                this.setState({ excelImpLoading: false });
                return false;
            });
    }
    // alkham 4
    addQuantity() {
        let notUpdateProducts = [];
        // console.log('this.quantityProd:',this.quantityProd);
        for (let i in this.quantityProd) {
            firebase.firestore().collection('product')
                .doc(String(this.quantityProd[i][0])).get()
                .then((res) => {
                    let existProduct = res.data();
                    // console.log("start upd:", existProduct);
                    existProduct.quantity = parseInt(existProduct.quantity) + parseInt(this.quantityProd[i][1])
                    // console.log("existProduct:", existProduct);
                    firebase.firestore().collection('product').
                        doc(existProduct.id).set(existProduct)
                        .then(() => {
                            if (i == this.quantityProd.length - 1); {
                                this.setOrderStatus({ runing: false, userno: 'admin', lastdate: new Date().toString() }, 4)
                                this.getProductDatas();
                                this.setState({ excelImpLoading: false });
                                alert("Шинчлэлт хийж дууслаа");
                            }
                        })
                        .catch(ex => {
                            console.log("firebase error: updateproduct " + ex);
                            notUpdateProducts.push(this.quantityProd[i][0]);
                            return;
                        });
                });
        }
        if (notUpdateProducts.length > 0) {
            //ene deer update oroogui nohduudiig ustgana.
        }
    }

    componentDidMount() {
        this.getProductTypes();
        this.getProductDatas();
    }

    render() {
        // console.log('render product content')
        return (
            <div className="content-wrapper">
                {this.state.excelImpLoading ?
                    <div className={css2.loader}></div> :
                    <div>
                        <div className="content-header">
                            <div className="container-fluid">

                                <StatusComponent />
                                <div className="row mb-2">
                                    <ProductControl productDatas={this.state.productDatas} fileHandler={this.fileHandler} refreshProduct={this.refreshProduct} mainimage={this.state.mainimage} productAction={this.productAction} formValid={true} setProduct={this.setAddProduct} product={this.state.product} isUpdate={this.state.isUpdate} isLoading={this.state.productLoading} errorMsg={this.state.errorMsg} handleInputChange={this.handleInputChange} productTypes={this.state.productTypes}></ProductControl>
                                </div>
                            </div>
                        </div>

                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        {this.state.tableLoading == true ? <div className={css.loader}></div> : <DataTable deleteProduct={this.deleteProduct} setProduct={this.setUpdateProduct} productTypes={this.state.productTypes} productDatas={this.state.productDatas} user={this.props.user} />}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                }
            </div>
        )
    }
}
export default ProductContent