import React from 'react';
import { validation } from '../../util/Validator'
import css from './style.module.css'
import { UserContext } from '../../components/context/UserContext'
import RegisterComponent from '../../components/user/Component/RegisterComponent'
import { Redirect } from 'react-router-dom'
const Index = (props) => {
    const ctx = React.useContext(UserContext)
    const [register, setRegister] = React.useState({
        email: '',
        phoneNumber: '',
        password: '',
        confPassword: '',
    });
    const [errorMsg, setErrorMsg] = React.useState({
        email: { show: false },
        phoneNumber: { show: false },
        password: { show: false },
        confPassword: { show: false },
    });
    const [formValid, setFormValid] = React.useState(false);

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const type = target.type;
        const validationTypes = {
            password: 'PASSWORD',
            confPassword: 'PASSWORD',
        }

        const validate = validation(value, validationTypes[name]);

        let formValid = false;

        if (!validate.show) {
            let istemvavlid = true;
            Object.values(register).map((el, index) => {
                if (el.length == 0 && Object.keys(register)[index] != [name]) {
                    istemvavlid = false;
                    return;
                }
            })
            if (istemvavlid) {
                formValid = true;
            }
        }
        setErrorMsg(prevState => ({
            ...prevState,
            [name]: validate
        }));
        setRegister(prevState => ({
            ...prevState,
            [name]: value
        }));
        setFormValid(formValid)
    }
    const submitData = () => {
        if (register.password === register.confPassword) {
            const email = register.email;
            const validator = validation(email, 'EMAIL');
            if (!validator.show) {
                const password = register.password;
                const returnSecureToken = true;
                const data = {
                    email, password, returnSecureToken
                }
                ctx.signUpUser(data, register.phoneNumber);
                // console.log("stateRegister", ctx.state);
                // console.log(data);
            } else {
                alert(validator.message);
            }
        } else {
            alert("password-ууд хоорондоо таарахгүй байна")
        }
    }
    return (
        ctx.state.registered ? <Redirect to="login"></Redirect> :
            ctx.state.loading ? <div className={css.loader}></div> :
                <RegisterComponent firebaseError={ctx.state.signUperror} formValid={formValid} handleInputChange={handleInputChange} submitData={submitData} errorMsg={errorMsg}></RegisterComponent>
    )
}


export default Index