import React, { Component } from 'react'
import axios from "axios";
import firebase from '../../../../../firebase'
import css from './style.module.css'

const Index = () => {
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    React.useEffect(() => {
        firebase.firestore().collection("users")
            .onSnapshot(querySnapshot => {
                setLoading(true);
                const userDatas = [];
                querySnapshot.forEach(function (doc) {

                    if (doc.data() != null)
                        userDatas.push(doc.data())
                });
                if (document.getElementById("datatableId"))
                    document.getElementById("datatableId").remove()
                const script = document.createElement("script");
                script.setAttribute('id', "datatableId");
                script.src = "../../js/content_datatable.js";
                script.async = true;
                document.body.appendChild(script);
                // console.log("changed => ", userDatas);
                setData(userDatas);
                setLoading(false);
            });
    }, [])

    const operations = (btnId, iss, user) => {
        let ids = document.getElementById(btnId);
        // console.log("sda yuma:", user.status);
        // console.log("sda yuma:", document.getElementById('select' + user.id).value);
        if (iss) {
            if (user.status != document.getElementById('select' + user.id).value)
                ids.style.display = 'block';
            else
                ids.style.display = 'none';
        }
        else {
            user.status = document.getElementById('select' + user.id).value;
            // console.log("changed user", user)
            firebase.firestore().collection('users').doc(user.id).set(user).then((res) => {
                ids.style.display = 'none';
            }).catch(ex => {
                console.log("servertei holbogdhod " + ex);
                alert("servertei hobogdhod aldaa garlaa", ex);
            })

        }
    }
    // console.log("render => ", data);
    return (
        loading ? <div>loading</div> : <div className="card">
            <div className="card-header">
                <h3 className="card-title">Хэрэглэчдийн жагсаалт</h3>
            </div>
            {/* /.card-header */}
            <div className="card-body" >
                <table id="example1" className="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>Email</th>
                            <th>Утасны дугаар</th>
                            <th>Төлөв</th>
                        </tr>
                    </thead>
                    <tbody>

                        {data.map((el, index) => (
                            <tr key={index}>
                                <td>  {el.email && el.email}</td>
                                <td>  {el.phoneNumber}</td>
                                <td>
                                    <div className={css.divBtnHide}>
                                        <select id={'select' + el.id} className={(el.status == 0?css.colorred:css.colorgreen )+" form-control"} onChange={() => operations('hideBtn' + el.id, true, el)}>
                                            <option value={el.status} selected>{el.status == 0 ? "Эрх хаалттай" : "Эрх нээлттэй"}</option>
                                            {el.status == 0 && <option value="1">Эрх нээх</option>}
                                            {el.status == 1 && <option value="0">Эрх хаах</option>}
                                        </select>
                                        <button id={'hideBtn' + el.id} onClick={() => operations('hideBtn' + el.id, false, el)} type="button" className={css.btnHidden}><i className='fa fa-check'></i></button>
                                    </div>
                                </td>
                            </tr>
                        ))}

                    </tbody>
                    <tfoot>
                        <tr>
                            <th>Email</th>
                            <th>Утасны дугаар</th>
                            <th>Төлөв</th>
                        </tr>
                    </tfoot>
                </table>
            </div>
            {/* /.card-body */}
        </div>

    )
}


export default Index;