import React, { Component } from 'react'
import axios from "axios";
import css from './style.module.css'
import firebase from '../../../../../firebase'

import { OrderStatuses } from '../../../../../util/OrderStatuses'
export default class index extends Component {
    constructor(props) {
        super(props);
        this.addQuantity = this.addQuantity.bind(this);
        this.state = {
            search : false,
            loading: false,
            data: [

            ],
        };
    }
    statusesColor = [
        '#17A2B8', '#28A745', '#FFC107', '#DC3545'
    ];
    // Барааны үлдэгдэл өөрчлөхөд ашиглана
    orderProds = [];
    temporder = {};
    tempids = null;
    tempadd = true;
    operations(btnId, iss, order) {

        let ids = document.getElementById(btnId);
        if (iss) {
            let value = document.getElementById('select' + order.orderid).value
            if (order.orderstatus != value) {
                ids.style.display = 'block';
            }
            else
                ids.style.display = 'none';
            document.getElementById('select' + order.orderid).style.color = this.statusesColor[value]
        }
        else {
            this.setState({ loading: true })
            // console.log("changed order", order)
            let oldstatus = order.orderstatus;
            order.orderstatus = document.getElementById('select' + order.orderid).value;
            this.orderProds = order.orderdetail;
            this.tempids = ids;
            this.temporder = order;
            // цуцалсан бараа бол
            if(order.orderstatus == 3)
            {
                this.tempadd = true;
                this.checkOrderStatus();
            }
            // хуучин төлөв цуцалсан бол
            else if(oldstatus == 3){
                this.tempadd = false;
                this.checkOrderStatus();
            }
            // бусад тохиолдолп
            else{
                this.changeOrderStatus();
            }
        }
    }

    changeOrderStatus = () => {
        firebase.firestore().collection('order').doc(this.temporder.orderid).set(this.temporder).then((res) => {
            this.tempids.style.display = 'none';
            alert("Шинчлэлт хийж дууслаа");
        }).catch(ex => {
            console.log("servertei holbogdhod " + ex);
            alert("Барааны үлдэгдэл нэмэгдсэн боловч захиалга цуцлагдсангүй");
        })
        this.setState({ loading: false })
        this.tempids.style.display = 'none'
    }

    // 2-р алхам Захиалга хийж болох эсхийг шалгах
    checkOrderStatus = () => {
        let orderstatus = null;
        firebase.firestore().collection("orderstatus")
            .get()
            .then(querySnapshot => {
                querySnapshot.forEach(function (doc) {
                    if (doc.data() != null) {
                        orderstatus = doc.data();
                    }
                });
                // console.log('orderstatus: ',orderstatus);
                if (orderstatus) {
                    if (orderstatus.runing) {
                        const lastUpdatedData = orderstatus.lastdate;
                        const date1 = new Date(lastUpdatedData);
                        const date2 = new Date();
                        const diffTime = Math.abs(date2 - date1);
                        const diffSec = Math.round(diffTime / (1000));
                        // console.log("sec", diffSec);
                        if (diffSec < 5) {
                            alert('Та түр хүлээгээд дахин оролдоно уу');
                            return false;
                        }
                        else {
                            return this.setOrderStatus({ runing: true, userno: 'admin', lastdate: new Date().toString() }, 3)
                        }
                    }
                    else {
                        return this.setOrderStatus({ runing: true, userno: 'admin', lastdate: new Date().toString() }, 3)
                    }
                }
                else {
                    return this.setOrderStatus({ runing: true, userno: 'admin', lastdate: new Date().toString() }, 3)
                }
            })
            .catch((ex) => {
                console.log("firebase error: alkham2 " + ex);
                alert("Барааны үлдэгдэл өөрчлөхөд алдаа гарлаа. Алхам 2");
                this.setState({ loading: false });
                return false;
            });
    }

    // 3-р алхам Захиалга хийж болно
    setOrderStatus = (orderstatus, alkham) => {
        // console.log('orderstatus:',orderstatus,alkham);
        firebase.firestore().collection('orderstatus').doc('1').set(orderstatus)
        .then(() => {
            if (alkham == 3) {
                this.addQuantity();
            }
        })
        .catch(ex => {
            console.log("firebase error: alkham" + alkham + ' ' + ex);
            alert("Барааны үлдэгдэл өөрлөхөд алдаа гарлаа. Алхам 3");
            this.setState({ loading: false });
            return false;
        });
    }
    // alkham 4
    addQuantity() {
        let notUpdateProducts = [];
        // console.log('this.orderProds:',this.orderProds);
        for (let i in this.orderProds) {
            firebase.firestore().collection('product')
                .doc(String(this.orderProds[i].id)).get()
                .then((res) => {
                    let existProduct = res.data();
                    // console.log("start upd:", existProduct);
                    if(this.tempadd){
                        existProduct.quantity = parseInt(existProduct.quantity) + parseInt(this.orderProds[i].quantity)
                    }
                    else{
                        // үлдэгдэл хасахруу орохоор 
                        existProduct.quantity = parseInt(existProduct.quantity) - parseInt(this.orderProds[i].quantity)
                    }
                    // console.log("existProduct:", existProduct);
                    firebase.firestore().collection('product').
                    doc(existProduct.id).set(existProduct)
                    .then(() => {
                        if (i == this.orderProds.length - 1); {
                            this.setOrderStatus({ runing: false, userno: 'admin', lastdate: new Date().toString() }, 4)
                            this.setState({ loading: false });
                            this.changeOrderStatus();
                        }
                    })
                    .catch(ex => {
                        console.log("firebase error: updateproduct " + ex);
                        notUpdateProducts.push(this.orderProds[i]);
                        return;
                    });
                });
        }
        if (notUpdateProducts.length > 0) {
            //ene deer update oroogui nohduudiig ustgana.
        }
        this.setState({ loading: false })
    }

    getProductTypeDatas() {
        
        firebase.firestore().collection("order")
            .get().then(querySnapshot => {
                // this.setState({ loading: true })
                const orderDatas = [];
                querySnapshot.forEach(function (doc) {
                    // console.log(doc.id, " => ", doc.data());
                    orderDatas.push(doc.data())
                });
                // console.table(orderDatas)
                // console.log("Datas=>", orderDatas);
                // if (document.getElementById("datatableId"))
                //     document.getElementById("datatableId").remove()
                // const script = document.createElement("script");
                // this.setState({ tableLoading: false })
                // script.setAttribute('id', "datatableId");
                // script.src = "../js/content_datatable.js";
                // script.async = true;
                // document.body.appendChild(script);
                this.setState({ data: orderDatas });
                
            });
    }
    componentDidMount() {
        this.getProductTypeDatas();
        
    }
    render() {
        let classNameHarah = [css.CardIconCenter, "btn btn-primary"];
        // console.log("sad", OrderStatuses('all'))
        return (
            <div>
            {this.state.loading == true ? <div className={css.loader}></div> : <div></div>}
            <div className="card">
                <div className="card-header" style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                    <h3 className="card-title">Захиалгын жагсаалт</h3>
                    <input type="text" onChange={(e)=>this.setState({search:e.target.value.toString()})} placeholder = "Дугаараар хайх..."/>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                    <table id="example1" className="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th>Захиалагын код</th>
                                <th>Утасны Дугаар</th>
                                <th>Хүргэх хаяг</th>
                                <th>Төлөв</th>
                                <th></th>
                            </tr>
                        </thead >
                        <tbody>
                            {this.state.search ? this.state.data.reverse().map((el, index) => (
                                (el.orderid.toString().includes(this.state.search)) &&
                                <tr key={index}>
                                    <td>{el.orderid}</td>
                                    <td>{el.userno}</td>
                                    <td>{el.deliveryaddress}</td>
                                    <td>
                                        <div className={css.divBtnHide}>
                                            <select key={el.orderid} style={{ fontWeight: 'bold', color: this.statusesColor[el.orderstatus] }} id={"select" + el.orderid} class="form-control" onChange={(event) => this.operations('hideBtn' + el.orderid, true, el, event)}>
                                                <option value={el.orderstatus} style={{ color: this.statusesColor[el.orderstatus] }} selected>{OrderStatuses(el.orderstatus).name}</option>
                                                {/* <option value="0">Зөвшөөрөх</option>
                                                <option value="1">Захиалагдсан</option>
                                                <option value="2">Хүргэлт</option>
                                                <option value="3">Цуцлах</option> */
                                                }
                                                {OrderStatuses('all').map((status, index) => (
                                                    el.orderstatus != index && <option key={index} style={{ fontWeight: 'bold', color: this.statusesColor[index] }} value={status.id}>{status.name}</option>
                                                ))}
                                            </select>
                                            <button id={'hideBtn' + el.orderid} onClick={() => this.operations('hideBtn' + el.orderid, false, el)} type="button" class={css.btnHidden}><i className='fa fa-check'></i></button>
                                        </div>
                                    </td>
                                    <td className={css.tdHarahCenter}>
                                        <button onClick={() => { this.props.setUpdateOrder(el) }} className={css.marginRight + " btn btn-success"} data-toggle="modal" data-target="#modal-default" ><i className="fas fa-search"></i>&nbsp;Харах</button>
                                    </td>
                                </tr>
                            ))  :  this.state.data.reverse().map((el, index) => (
                                <tr key={index}>
                                    <td>{el.orderid}</td>
                                    <td>{el.userno}</td>
                                    <td>{el.deliveryaddress}</td>
                                    <td>
                                        <div className={css.divBtnHide}>
                                            <select key={el.orderid} style={{ fontWeight: 'bold', color: this.statusesColor[el.orderstatus] }} id={"select" + el.orderid} class="form-control" onChange={(event) => this.operations('hideBtn' + el.orderid, true, el, event)}>
                                                <option value={el.orderstatus} style={{ color: this.statusesColor[el.orderstatus] }} selected>{OrderStatuses(el.orderstatus).name}</option>
                                                {/* <option value="0">Зөвшөөрөх</option>
                                                <option value="1">Захиалагдсан</option>
                                                <option value="2">Хүргэлт</option>
                                                <option value="3">Цуцлах</option> */
                                                }
                                                {OrderStatuses('all').map((status, index) => (
                                                    el.orderstatus != index && <option key={index} style={{ fontWeight: 'bold', color: this.statusesColor[index] }} value={status.id}>{status.name}</option>
                                                ))}
                                            </select>
                                            <button id={'hideBtn' + el.orderid} onClick={() => this.operations('hideBtn' + el.orderid, false, el)} type="button" class={css.btnHidden}><i className='fa fa-check'></i></button>
                                        </div>
                                    </td>
                                    <td className={css.tdHarahCenter}>
                                        <button onClick={() => { this.props.setUpdateOrder(el) }} className={css.marginRight + " btn btn-success"} data-toggle="modal" data-target="#modal-default" ><i className="fas fa-search"></i>&nbsp;Харах</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>Захиалагын код</th>
                                <th>Утасны Дугаар</th>
                                <th>Хүргэх хаяг</th>
                                <th>Төлөв</th>
                                <th></th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                {/* /.card-body */}
            </div >
        </div>
        )
    }
}
