import React from 'react'
import css from './style.module.css'
import BannerAdd from '../BannerAdd/index'
const BannerControl = (props) => {
    const bannerFormClose = () => {
        if (document.getElementById("exampleInputFile"))
            document.getElementById("exampleInputFile").value = '';
    }

    return (
        <div className="col-sm-12 text-right">
            <button onClick={() => {
                props.setBanner();
            }} className={css.marginRight + " btn btn-success"} data-toggle="modal" data-target="#modal-default" ><i className="fas fa-plus"></i> Нэмэх</button>

            <div className="modal fade" id="modal-default" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Баннер нэмэх</h4>
                            <button id="exit" type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => { bannerFormClose() }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        {props.isLoading == true ? <div className={css.loader}></div> : <div className="modal-body">
                            <div className="card card-primary" >
                                <div className="card-body" style={{ "textAlign": "left" }}>
                                    <BannerAdd mainimage={props.mainimage} bannerAction={props.bannerAction} isLoading={props.isLoading} banner={props.banner} errorMsg={props.errorMsg} handleInputChange={props.handleInputChange} isUpdate={props.isUpdate}></BannerAdd>
                                    {props.isUpdate == true ? <div className="modal-footer justify-content-between">
                                        <button type="button" className="btn btn-danger" data-dismiss="modal">Хаах</button>
                                        <button type="submit" onClick={props.bannerAction} disabled={props.formValid ? "" : "disabled"} className="btn btn-success">Засах</button>
                                        {/* <button type="button" onClick={props.refreshProduct} className="btn btn-info"><i className="fas fa-sync-alt"></i></button> */}
                                    </div>
                                        : <div className="modal-footer justify-content-between">
                                            <button type="button" className="btn btn-danger" onClick={() => { bannerFormClose() }} data-dismiss="modal">Хаах</button>
                                            <button type="submit" onClick={props.bannerAction} disabled={props.formValid ? "" : "disabled"} className="btn btn-success">Хадгалах</button>
                                        </div>}
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>

        </div >
    )
}

export default BannerControl;