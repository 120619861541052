import React, { Component } from "react";
import { NavLink } from "react-router-dom";
export default class Menu extends Component {
  render() {
    return (
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <NavLink to="/" activeClassName="brand-link" className="brand-link">
          {/* <a href="index3.html" className="brand-link"> */}

          <span className="brand-text font-weight-light"></span>
        </NavLink>
        {/* Sidebar */}
        <div className="sidebar">
          {/* Sidebar user panel (optional) */}
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
              <img
                src={process.env.PUBLIC_URL + "/dist/img/logoZow.jpg"}
                className="img-circle elevation-2"
                alt="User Image"
              />
            </div>
            <div className="info">
              <NavLink to="/admin" activeClassName="d-block">
                Админ
              </NavLink>
            </div>
          </div>
          <div className="form-inline">
            {/* <div className="input-group" data-widget="sidebar-search">
                            <input className="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search" />
                            <div className="input-group-append">
                                <button className="btn btn-sidebar">
                                    <i className="fas fa-search fa-fw" />
                                </button>
                            </div>
                        </div> */}
          </div>
          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}

              <li className="nav-item ">
                <NavLink
                  to="/admin/product"
                  className="nav-link"
                  activeClassName="nav-link"
                >
                  <i className="nav-icon fas fa-folder" />
                  <p>Бүтээгдэхүүн</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/admin/product/type"
                  className="nav-link"
                  activeClassName="nav-link"
                >
                  <i className="nav-icon fas fa-folder" />
                  <p>Бүтээгдэхүүн төрөл</p>
                </NavLink>
              </li>

              <li className="nav-item ">
                <NavLink
                  to="/admin/orders"
                  className="nav-link"
                  activeClassName="nav-link"
                >
                  <i className="nav-icon fas fa-folder" />
                  <p>
                    Захиалгын жагсаалт
                    {/*  <span className="badge badge-info right">6</span> */}
                  </p>
                </NavLink>
              </li>
              <li className="nav-item ">
                <NavLink
                  to="/admin/users"
                  className="nav-link"
                  activeClassName="nav-link"
                >
                  <i className="nav-icon fas fa-folder" />
                  <p>
                    Хэрэглэгчийн жагсаалт
                    {/*  <span className="badge badge-info right">6</span> */}
                  </p>
                </NavLink>
              </li>
              {/* <li className="nav-item " >
                                <NavLink to="/admin/exchange" className="nav-link" activeClassName="nav-link" >
                                    <i className="nav-icon fas fa-folder" />
                                    <p>Ханш</p>
                                </NavLink>
                            </li> */}
              <li className="nav-item ">
                <NavLink
                  to="/admin/banner"
                  className="nav-link"
                  activeClassName="nav-link"
                >
                  <i className="nav-icon fas fa-folder" />
                  <p>Banner</p>
                </NavLink>
              </li>
              <li className="nav-item ">
                <NavLink
                  to="/admin/courses"
                  className="nav-link"
                  activeClassName="nav-link"
                >
                  <i className="nav-icon fas fa-folder" />
                  <p>Сургалт</p>
                </NavLink>
              </li>
              {/* <li className="nav-item ">
                <NavLink
                  to="/admin/page2"
                  className="nav-link"
                  activeClassName="nav-link"
                >
                  <i className="nav-icon fas fa-folder" />
                  <p>Page 2 зураг</p>
                </NavLink>
              </li> */}
              {/* <li className="nav-item ">
                <NavLink
                  to="/admin/cards"
                  className="nav-link"
                  activeClassName="nav-link"
                >
                  <i className="nav-icon fas fa-folder" />
                  <p>BeSmart кардууд</p>
                </NavLink>
              </li> */}
              <li className="nav-item ">
                <NavLink
                  to="/admin/sitedata"
                  className="nav-link"
                  activeClassName="nav-link"
                >
                  <i className="nav-icon fas fa-folder" />
                  <p>Үйлчилгээний нөхцөл</p>
                </NavLink>
              </li>
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
    );
  }
}
