import React from 'react'
import logo from "./assets/logo.jpg"

import css from './style.module.css'
import { NavLink } from 'react-router-dom'
export default function index(props) {
    return (
        <div className={css.mainDiv} onKeyPress={(e)=>{if(e.key === 'Enter'){props.submitData()}}}>
            <div className="col-md-12 text-center">
                <div >{props.status == 0 && <center><p className={css.alertMessage}>Та амжилттай бүртгүүлсэн байна. Та хэрэглэгчийн эрхээ 89111233 дугаарт залгаж нээлгэнэ үү баярлалаа.</p></center>}</div>

                <h1 style={{marginBottom:'20px'}}>Нэвтрэх</h1>
            </div>
            <div className="form-group">
                <input name='email' id='exampleInputId' type="email" className="form-control" placeholder="email хаяг" autoComplete="off" onChange={(event) => { props.handleInputChange(event) }} />
            </div>
            <p className={css.errorMsg}>{props.errorMsg.email.show && props.errorMsg.email.message}</p>
            <div className="form-group">
                <input name='password' id='exampleInputId' type="password" className="form-control" placeholder="Нууц үг" autoComplete="off" onChange={(event) => { props.handleInputChange(event) }} />
            </div>
            <p className={css.errorMsg}>{props.errorMsg.password.show && props.errorMsg.password.message}</p>
            <button type="submit" onClick={props.submitData} style={{ backgroundColor: "#009e7f" }} className="btn btn-success" disabled={props.formValid ? "" : "disabled"}>Нэвтрэх</button>

            <p className={css.errorMsg}>{props.firebaseError}</p>
            <p className="text-center">Таньд бүртгэл байгаа юу? <a href="/register" id="signup">Бүртгүүлэх</a></p>
            <p className="text-center"> <a href="/forgot" id="signup">Нууц үгээ мартсан</a></p>
            {/* <div onClick={()=>window.location.href="/cardadd"} style={{width:'100%',background:'#161620',display:'flex',alignItems:'center',justifyContent:'space-between',borderRadius:'20px',marginTop:'10px',cursor:'pointer'}}>
                <img alt='' src={logo} style={{width:'100px',borderRadius:'20px'}}/>
                
                <span style={{width:'100%',textAlign:'center',color:'#F3E68A',fontWeight:'bold',fontSize:'large'}}>Карт үүсгэх</span>
                
            </div> */}
        </div>
    )
}
