import React from 'react'

import css from './style.module.css'
import { NavLink } from 'react-router-dom'
export default function index(props) {
    return (
        <div className={css.mainDiv}>
            <div className="col-md-12 text-center">
                <div >{props.status == 0 && <center><p className={css.alertMessage}>Та амжиллтай бүртгүүлсэн байна. Та хэрэглэгчийн эрхээ 89111233 дугаарт залгаж нээлгэнэ үү баярлалаа.</p></center>}</div>

                <h1>Нууц үгээ шинчлэх</h1>
            </div>
            <div className="form-group">
                <input name='email' id='exampleInputId' type="email" className="form-control" placeholder="email хаяг" autoComplete="off" onChange={(event) => { props.handleInputChange(event) }} />
            </div>
            <p className={css.errorMsg}>{props.errorMsg.email.show && props.errorMsg.email.message}</p>

            <button type="submit" onClick={props.submitData} style={{ backgroundColor: "#009e7f" }} className="btn btn-success" disabled={props.formValid ? "" : "disabled"}>Илгээх</button>
            <p className={css.errorMsg}>{props.firebaseError}</p>

            <p className="text-center"> <NavLink to="/login" id="signup">Буцах</NavLink></p>

        </div>
    )
}
