import React from "react";

import firebase from "../../../../firebase";
import css from "./style.module.css";
import Carousel from "react-elastic-carousel";

import cssLoading from "../ProductList/style.module.css";
const Slider = () => {
  const [banners, setBanners] = React.useState([]);
  const [loading, setLoading] = React.useState([]);
  let images = [
    {
      image: null,
      caption: "Caption for image_1",
      description: "Description for image_1",
    },
  ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  React.useEffect(() => {
    setLoading(true);
    firebase
      .firestore()
      .collection("banner")
      .get()
      .then((collections) => {
        const banners = collections.docs.map((banner) => banner.data());
        banners.map((banner) => {
          images.push({
            image: banner.image,
            caption: "Caption for image_1",
            description: "Description for image_1",
          });
        });
        images.shift();

        setBanners(banners);
        setLoading(false);
      });
  }, []);
return loading ? (
    <div className={cssLoading.loader2}></div>
) : (
    <div>
        <Carousel
            enableMouseSwipe={true}
            enableSwipe={true}
            pagination={false}
            transitionMs={500}
            showArrows={false}
            autoPlaySpeed={4000}
            enableAutoPlay={true}
            itemsToShow={1}
            infiniteLoop={true} // Added to make it auto-slide unlimited
        >
            {banners.map((banner, index) => (
                <div key={index}>
                    <img src={banner.image} className={css.dHQHGmImg} alt={`banner-${index}`} />
                </div>
            ))}
        </Carousel>
    </div>
);
};

export default Slider;
