import React, { Component } from 'react'
import css from './style.module.css'

export default class index extends Component {
    render() {
        let icjzhSeditbtn = [css.icjzhS, css.editbtncheckout];
        let icjzhSdelbtn = [css.icjzhS, css.deletebtncheckout];
        let kOmOCEactive = [css.kOmOCE, this.props.active && css.active];
        return (
            <label htmlFor={this.props.radioname+this.props.id} className={kOmOCEactive.join(' ')}>
                <input type="radio" id={this.props.radioname+this.props.id} name={this.props.radioname} value={this.props.radiodata.val}></input>
                <span className={css.jNeWVx}>{this.props.radiodata.name}</span>
                <span className={css.hsUQDG}>{this.props.radiodata.val}</span>
                <span className={css.jTaKCq}>
                    <span className={icjzhSeditbtn.join(' ')} data-toggle="modal" data-target={"#modal-"+this.props.radioname} onClick={()=>{this.props.setUpdateData(this.props.radiodata,this.props.id)}}>
                        <i className='fa fa-pen'></i>
                    </span>
                    <span className={icjzhSdelbtn.join(' ')} onClick={()=>{this.props.delData(this.props.id)}}>
                        <i className='fa fa-times'></i>
                    </span>
                </span>
            </label>
        )
    }
}
