export const OrderStatuses = (pid) => {
    const statuses = [
        {
            id: 0,
            name: 'Хүлээн авсан'
        },
        {
            id: 1,
            name: 'Төлөгдсөн'
        },
        {
            id: 2,
            name: 'Хүргэгдсэн'
        },
        {
            id: 3,
            name: 'Цуцлагдсан'
        }
    ];
    let result = {
        id: -1,
        name: 'Тодорхоогүй'
    };
    statuses.forEach(el => {

        if (el.id == pid) {
            // console.log('el:',el);
            result = el;
        }
    })
    if (pid === 'all') {
        return statuses;
    } else {
        return result;
    }
}
