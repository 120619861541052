import React, { Component } from 'react'
import DataTable from '../../DataTable/products/ProductTypeDataTable'
import ProductTypeControl from '../../components/ProductTypeControl'
import axios from './../../../../axios'
import $ from 'jquery'
import { validation } from '../../../../util/Validator'
import firebase from "./../../../../firebase";
import tableName from './../../../../util/TableNames'
class ProductTypeContent extends React.Component {
    defaultStatus = {
        productLoading: false,
        isUpdate: false,
        mainimage: '',
        productType: {
            id: '',
            name: '',
            description: '',
            color: '#FFFFFF',
            image: '',
            sorting: 0

        },
        errorMsg: {

            id: { show: false },
            name: { show: false },
            description: { show: false },
            image: { show: false },
            sorting: { show: false },

        }
    };
    tempProductType = {};
    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.productTypeAction = this.productTypeAction.bind(this);
        this.setUpdateProductType = this.setUpdateProductType.bind(this);
        this.setAddProductType = this.setAddProductType.bind(this);
        this.state = {
            ...this.defaultStatus,
            productDatas: [],
            tableLoading: true,
            productTypes: [{
                id: '',
                name: '',
                description: '',
                image: '',
                color: '#FFFFFF',
                sorting: 0
            }],
        }
    }
    //
    productTypeAdd(productType) {
        firebase.firestore().collection('productType').doc(productType.id).set(productType).then((ref) => {
            this.getProductTypes();
            alert("амжилттай");
            if (this.state.isUpdate) {
                $('#exitType').click();
            }
        }).catch(ex => {
            console.log("servertei holbogdhod " + ex);
            alert("servertei hobogdhod aldaa garlaa", ex);
        }).finally(
            this.setState({
                productType: {
                    ...this.defaultStatus.productType,
                },
                productLoading: false,
            })
        );
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const type = target.type;
        const validationTypes = {
            price: 'POSITIVENUMBER',
            id: 'EQUAL'
        }
        // console.log("value123456:", event.target.value)
        if (type == 'file') {
            if (event.target.files[0]) {
                this.setState({ mainimage: event.target.files[0] });
            }
        }
        const validate = validation(value, validationTypes[name], this.state.productDatas);

        let formValid = false;

        if (!validate.show) {
            let istemvavlid = true;
            Object.values(this.state.productType).map((el, index) => {
                if (el.length == 0 && Object.keys(this.state.productType)[index] != [name]) {
                    istemvavlid = false;
                    return;
                }
            })
            if (istemvavlid) {
                formValid = true;
            }
        }
        this.setState({
            errorMsg: {
                ...this.state.errorMsg,
                [name]: validate
            },
            productType: {
                ...this.state.productType,
                [name]: value
            },
            formValid: formValid
        });
    }

    productTypeAction = () => {
        this.setState({ productLoading: true, tableLoading: true });
        // this.productTypeAdd(this.state.productType);
        if (!this.state.mainimage && this.state.isUpdate) {
            this.productTypeAdd(this.state.productType);
        }
        else {
            const uploadTask = firebase.storage().ref(`images/productType/${this.state.productType.id}`).put(this.state.mainimage);
            uploadTask.on(
                "state_changed",
                snapshot => {
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                },
                error => {
                    console.log(error);
                },
                () => {
                    firebase.storage()
                        .ref("images/productType")
                        .child(this.state.productType.id)
                        .getDownloadURL()
                        .then(url => {
                            this.setState({
                                productType: {
                                    ...this.state.productType,
                                    image: url
                                }
                            })
                            this.productTypeAdd(this.state.productType);
                        });
                }
            );
        }
    };
    setUpdateProductType(updatedProductType, isUpdate) {
        console.log("type", updatedProductType);
        console.log("prt", this.state.productType);
        this.tempProductType = updatedProductType;
        this.defaultStatus.productType = {...updatedProductType};
        this.setState({
            ...this.defaultStatus,
            mainimage: null,
            formValid: true,
            isUpdate: isUpdate,
            // productType: { ...this.productType, ...updatedProductType },

        });
        console.log("prt", this.state.productType);
    }
    setAddProductType() {
        this.setState({ ...this.defaultStatus, formValid: false });
    }

    refreshProductType = () => {
        document.getElementById("exampleInputFile").value = '';
        this.setState({
            productType: this.tempProductType,
            mainimage: ''
        });
    }

    getProductTypes() {

        firebase.firestore().collection("productType")
            .get().then(querySnapshot => {
                // console.log("duudagldaa")
                this.setState({ tableLoading: true });
                const productTypeDatas = [];
                querySnapshot.forEach(function (doc) {
                    productTypeDatas.push(doc.data())
                });
                if (document.getElementById("datatableId"))
                    document.getElementById("datatableId").remove()
                const script = document.createElement("script");
                this.setState({ tableLoading: false })
                script.setAttribute('id', "datatableId");
                script.src = "../../js/content_datatable.js";
                script.async = true;
                document.body.appendChild(script);
                this.setState({ productDatas: productTypeDatas });

            });
    }

    componentDidMount() {
        this.getProductTypes();
    }

    render() {
        // console.log('render product content')
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">

                        <div className="row mb-2">
                            <ProductTypeControl mainimage={this.state.mainimage} productTypeAction={this.productTypeAction} formValid={this.state.formValid} setProductType={this.setAddProductType} isUpdate={this.state.isUpdate} isLoading={this.state.productLoading} refreshProductType={this.refreshProductType} productType={this.state.productType} errorMsg={this.state.errorMsg} handleInputChange={this.handleInputChange} productTypes={this.state.productTypes}></ProductTypeControl>
                        </div>
                    </div>
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <DataTable setProductType={this.setUpdateProductType}
                                    isLoading={this.state.tableLoading} productTypes={this.state.productTypes} productDatas={this.state.productDatas} />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
export default ProductTypeContent