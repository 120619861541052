import React, { useState } from 'react';
import { validation } from '../../util/Validator'
import ForgotPassword from '../../components/user/Component/ForgotPassword'
import { Redirect, withRouter } from 'react-router-dom'
import { UserContext } from '../../components/context/UserContext'
const Index = () => {
    const ctx = React.useContext(UserContext);
    const resetPassword = ctx.resetPassword;
    const [email, setEmail] = useState("");
    const [errorMsg, setErrorMsg] = useState({
        email: { show: false }
    });
    const [formValid, setFormValid] = useState("");

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const type = target.type;
        const validationTypes = {
            price: 'POSITIVENUMBER',
            id: 'EQUAL'
        }

        const validate = validation(value, validationTypes[name], name == 'id');

        let formValid = false;

        if (!validate.show) {
            formValid = true;
        }
        setErrorMsg(prevState => ({
            ...prevState,
            [name]: validate
        }));
        setEmail(prevState => ({
            ...prevState,
            [name]: value
        }));
        setFormValid(formValid)
    }
    const submitData = () => {
        // console.log("email", email.email)
        const validator = validation(email.email, 'EMAIL');
        if (!validator.show) {
            ctx.resetPassword(email.email);
        } else {
            alert(validator.message);
        }
    }
    // console.log("ctx", email)
    return (<ForgotPassword resetPassword={resetPassword} firebaseError={ctx.state.forgotError} formValid={formValid} handleInputChange={handleInputChange} errorMsg={errorMsg} submitData={submitData}></ForgotPassword>
    )
}

export default withRouter(Index);