import React, {useState} from 'react'
import firebase from "../../../firebase";
import {ExchangeContext} from '../ExchangeContext' 

export const BasketContext = React.createContext();
export const BasketProvider = (props) => {
    const ctxExchange = React.useContext(ExchangeContext);
    const initialexchange = ctxExchange.state.exchange;
    const localStoreKey = 'basket';
    const [basket, setBasket] = useState([]);
    const [show, setShow] = useState(false);
    const [totalprice, setTotalprice] = useState(0);
    const [totalprice1, setTotalprice1] = useState(0);
    // const [totalprice2, setTotalprice2] = useState(0);
    const [totalpoint, setTotalpoint] = useState(0);
    const [exchange, setExchange] = useState(ctxExchange && initialexchange.done ? initialexchange.data.rate:-1);
    const [isloading, setIsLoading] = React.useState(false);
    let localBasketStr = localStorage.getItem(localStoreKey) || '';
    // ханш өөрчлөгдөх үед мэдрэх
    React.useEffect(() => {
        setExchange(ctxExchange && ctxExchange.state.exchange.done ? ctxExchange.state.exchange.data.rate:-1);
    },[ctxExchange]);
    React.useEffect(() => {
        let localbasket = readFromLocalStore();
        if(localbasket.length>0){
            localbasket.map((item)=>{
                if(item.length==2){
                    firebase.firestore().collection('product').where('id', '==', item[0]).get()
                    .then((collections) => {
                        const products = collections.docs.map((product) => product.data());
                        if(products.length>0)
                        {
                            let addbasketproduc = products[0];
                            addbasketproduc.qty = parseInt(item[1]);
                            setBasket(currentState => [...currentState,addbasketproduc])
                        }
                    })
                }
            });
        }
    }, []);
    React.useEffect(()=>{
        setTotalprice(basket.reduce((acc, curr) => acc + curr.price * curr.qty, 0));
        setTotalprice1(basket.reduce((acc, curr) => acc + curr.price1 * curr.qty, 0));
        // setTotalprice2(basket.reduce((acc, curr) => acc + curr.price2 * curr.qty, 0));
        setTotalpoint(basket.reduce((acc, curr) => acc + curr.point * curr.qty, 0).toFixed(2));
    },[basket])
    const addToBasket = (tproduct) => {
        let pos = basket.map((e) => { return e.id; }).indexOf(tproduct.id);
        if (pos == -1) {
            basket.push(tproduct);
            pos = basket.length - 1;
        }
        else{
            basket[pos].qty += 1;
        }
        // local storage д хадгалах
        addLocalStore(basket[pos].id);
        setBasket(currentState => [...currentState]);
    }
    const removeFromBasket = (tproduct) => {
        let pos = basket.map((e) => { return e.id; }).indexOf(tproduct.id);
        if (pos > -1) {
            if(basket[pos].qty == 1){
                basket.splice(pos, 1);
            }
            else{
                basket[pos].qty -= 1;
            }
            removeLocalStore(tproduct.id);
            setBasket(currentState => [...currentState]);
        }
    }
    const removeAllFromBasket = (tproduct) => {
        let pos = basket.map((e) => { return e.id; }).indexOf(tproduct.id);
        if (pos > -1) {
            basket.splice(pos, 1);
            removeLocalStore(tproduct.id, true);
            setBasket(currentState => [...currentState]);
        }
    }
    const clearBasket = () =>{
        setBasket([]);
        removeAllLocalStore();
    }
    const readFromLocalStore = () =>{
        let res = localBasketStr.split(';');
        res = res.map((item)=>(
            item.split('/')
        ));
        return res;
    }
    const addLocalStore = (id) =>{
        let res = localBasketStr.split(';');
        localBasketStr = '';
        let isupdate = false;
        res.map((item)=>{
            if(item.length>2){
                let res2 = item.split('/');
                if(res2[0] == id){
                    res2[1] = parseInt(res2[1]) + 1;
                    item = res2.join('/');
                    isupdate = true;
                }
                localBasketStr += item + ';'
            }
        });
        if(isupdate){
            localStorage.setItem(localStoreKey, localBasketStr);
        }
        else{
            localStorage.setItem(localStoreKey, localBasketStr + id + '/1' + ';');
        }
    }
    const removeLocalStore = (id, all=false) =>{
        let res = localBasketStr.split(';');
        localBasketStr = '';
        let isupdate = false;
        res.map((item)=>{
            if(item.length>2){
                let res2 = item.split('/');
                if(res2[0] == id){
                    res2[1] = all? 0: parseInt(res2[1]) - 1;
                    if(res2[1] <= 0){
                        item = '';
                    }
                    else
                        item = res2.join('/');
                }
                localBasketStr += item + (item == ''?'': ';');
            }
        });
        localStorage.setItem(localStoreKey, localBasketStr);
    }
    const removeAllLocalStore = () =>{
        localStorage.removeItem(localStoreKey);
    }
    return(
        <BasketContext.Provider value={{basket,setBasket,show,setShow,addToBasket,removeFromBasket,removeAllFromBasket,clearBasket,totalprice,totalprice1,totalpoint,exchange,isloading,setIsLoading}}>
            {props.children}
        </BasketContext.Provider>
    );
}