import React, { Component } from 'react'
import OrderDataTable from '../../DataTable/orders/OrderDataTable'
import StatusComponent from '../../components/StatusComponent'
import OrderControl from '../../DataTable/orders/OrderControl/index'
export default class Content extends Component {

    constructor(props) {
        super(props);
        this.setUpdateOrder = this.setUpdateOrder.bind(this);
        this.state = {
            order: {}
        }
    }

    setUpdateOrder(pOrder) {
        this.setState({
            order: pOrder
        });
    }
    render() {
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <OrderControl order={this.state.order}></OrderControl>
                        </div>
                    </div>
                </div>
                <section id="orderSection" className="content">
                    <div className="container-fluid">
                        <StatusComponent />
                        <div className="row">
                            <div className="col-12">
                                <OrderDataTable setUpdateOrder={this.setUpdateOrder} />
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        )
    }
}
