import React, { useContext } from 'react'
import {BasketContext} from '../../../context/BasketContext' 

import css from './style.module.css'

export const BasketItem = (props) => {
    const ctx = useContext(BasketContext);
    const tproduct = { ...props.item};
    // console.log('tproduct: ',tproduct);
    return (
        <div className={css.bOpSCc}>
            <div className={css.bkLFHu}>
                <button className={css.cbNtye} onClick={()=>{ctx.removeFromBasket(tproduct)}}>
                    <i className="fas fa-minus"></i>
                </button>
                <span className
                // =
                // {
                //     (props.item.quantity<props.item.qty ? css.colorred:'')
                //     }
                    >{props.item.qty}</span>
                {/* {props.item.quantity<=props.item.qty?
                <button className={css.cbNtye} onClick={() => { alert('"'+props.item.name+'" барааны үлдэгдэл хүрэлцэхгүй байна')}}>
                    <i className="fa fa-plus"></i>
                </button>: */}
                <button className={css.cbNtye} onClick={()=>{ctx.addToBasket(tproduct)}}>
                    <i className="fas fa-plus"></i>
                </button>
                {/* } */}
            </div>
            <div className={css.flexcolumn}>
                <img src={props.item.image} className={css.kyNexk}></img>
            </div>
            <div className={css.flexcolumn + ' ' + css.height100p}>
                <div className={css.djMkq}>{props.item.name}</div>
                <div className={css.flexrow+' '+css.margintop2}>
                    <div className={css.flexcolumn+' '+css.fFQivv}>
                        <span className={css.gKpPoA + ' ' + css.flexcolumn}>{props.item.qty}{`ш`}</span>
                    </div>
                    <div className={css.flexcolumn+' '+css.fFQivv}>
                        <span className={css.ewWbZD}>{props.item.price * props.item.qty}₮/<span className={css.productpercent}>15%</span>/</span>
                        {/* <span className={css.ewWbZD}>{props.item.price1 * props.item.qty}₮/<span className={css.productpercent}>23%</span>/</span> */}
                        {/* <span className={css.ewWbZD}>{props.item.price2 * props.item.qty}₮/<span className={css.productpercent}>100%</span>/</span> */}
                        <span className={css.ewWbZD2}>{(props.item.point * props.item.qty).toFixed(2)}оноо</span>
                    </div>
                </div>
            </div>
            <button className={css.ijpSFz} onClick={()=>{ctx.removeAllFromBasket(tproduct)}}>
                <i className="fa fa-times"></i>
            </button>
        </div>
    )
}
