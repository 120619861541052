import React from 'react'
import css from './style.module.css'
import ProductTypeAdd from '../ProductTypeAdd'
export default class ProductTypeControl extends React.Component {

    render() {
        return (
            <div className="col-sm-12 text-right">
                <button onClick={() => {
                    this.props.setProductType();
                }} className={css.marginRight + " btn btn-success"} data-toggle="modal" data-target="#modal-default" ><i className="fas fa-plus"></i>Төрөл Нэмэх</button>
                <div className="modal fade" id="modal-default" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Төрөл нэмэх</h4>
                                <button id="exitType" type="button" className="close" data-dismiss="modal" aria-label="Close" >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            {this.props.isLoading == true ? <div className={css.loader}></div> :
                                <div className="modal-body">
                                    <div className="card card-primary" >
                                        <div className="card-body" style={{ "textAlign": "left" }}>
                                            <ProductTypeAdd productTypeAction={this.props.productTypeAction} isLoading={this.props.isLoading} productType={this.props.productType} errorMsg={this.props.errorMsg} handleInputChange={this.props.handleInputChange} isUpdate={this.props.isUpdate}></ProductTypeAdd>
                                            {this.props.isUpdate == true ? <div className="modal-footer justify-content-between">
                                                <button type="button" className="btn btn-danger" data-dismiss="modal">Хаах</button>

                                                <button type="submit" onClick={this.props.productTypeAction} disabled={this.props.formValid ? "" : "disabled"} className="btn btn-success">Засах</button>
                                                <button type="button" onClick={this.props.refreshProductType} className="btn btn-info"><i className="fas fa-sync-alt"></i></button>
                                            </div>
                                                : <div className="modal-footer justify-content-between">
                                                    <button type="button" className="btn btn-danger" data-dismiss="modal">Хаах</button>
                                                    <button type="submit" onClick={this.props.productTypeAction} disabled={this.props.formValid ? "" : "disabled"} className="btn btn-success">Хадгалах</button>
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
