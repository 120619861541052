import React from 'react'

import css from './style.module.css'
export const Webview = (props) =>{
    return(
        <div className={css.dEBCwJ}>
            <div className={css.lihBKG}>
                <span className={css.hjcFuj}>
                    {'Захиалга'}<br></br>
                    <span className={css.font11}>{'#'}{props.numb}</span>
                </span>
                <span className={css.iTdekY}>
                    {props.statusname}
                </span>
            </div>
            <div className={css.guZSJO}>
                <div className={css.Bmugg}>
                    {'Захиалсан өдөр'}{':'}
                    <span>{props.orderdate}</span>
                </div>
                <div className={css.Bmugg+' '+css.price}>
                    {'Нийт оноо'}{':'}
                    <span>{props.totalpoint}</span>
                </div>
            </div>
        </div>
    )
}