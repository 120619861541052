import React from 'react'
import {OrderStatuses} from '../../../../util/OrderStatuses'

import css from './style.module.css'
export const WebViewDetail = (props) =>{
    let inorderdetail = null;
    let checked0 = '';
    let checked1 = '';
    let checked2 = '';
    let checked3 = '';
    if(props.orderDetail){
        if(props.orderDetail.orderstatus == 3){
            checked3 = ' ' +css.checked2;
        }
        else if(props.orderDetail.orderstatus == 2){
            checked2 = ' ' +css.checked;
            checked1 = ' ' +css.checked;
            checked0 = ' ' +css.checked;
        }
        else if(props.orderDetail.orderstatus == 1){
            checked1 = ' ' +css.checked;
            checked0 = ' ' +css.checked;
        }
        else{
            checked0 = ' ' +css.checked;
        }
        inorderdetail = props.orderDetail.orderdetail.map(el=>{
            props.orderProducts.forEach(itt=>{
                if(el.id == itt.id){
                    el.productimage = itt.image;
                    el.productname = itt.name;
                }
            })
            return el
        });
    }
    return(
        <div className={css.cJGveh}>
            {props.orderDetail?
            <div>
            <h3 className={css.kVstku}>Захиалгын дэлгэрэнгүй</h3>
            <div className={css.jjWgRb}>
                <div className={css.bLiRNB}>
                    <h3>Хүргэх хаяг</h3>
                    <span className={css.fbmTwo}>{props.orderDetail.deliveryaddress}</span>
                </div>
                <div className={css.bPJmao}>
                    <div className={css.cqjOZT}>
                        {'Үнэ15%'}
                        <div className={css.cFTfrZ}>
                            ₮
                            {props.orderDetail.totalprice}
                        </div>
                    </div>
                    <div className={css.cqjOZT}>
                        {/* {'Үнэ23%'}
                        <div className={css.cFTfrZ}>
                            ₮
                            {props.orderDetail.totalprice1}
                        </div> */}
                    </div>
                    {/* <div className={css.cqjOZT}>
                        {'Үнэ100%'}
                        <div className={css.cFTfrZ}>
                            ₮
                            {props.orderDetail.totalprice2}
                        </div>
                    </div> */}
                    <div className={css.cqjOZT +' '+css.grandTotal}>
                        {'Нийт оноо'}
                        <div className={css.cFTfrZ}>
                            {props.orderDetail.totalpoint}
                        </div>
                    </div>
                </div>
            </div>
            <div className={css.lbTChn}>
                <div className={css.foKLRz}>
                    <div className={css.jYdTsb}>
                        <div className={css.jYdTsb+' '+css.flwWPp+checked0}>
                            {checked0==''?'1':
                            <span className={css.fRVGLf}>
                                <i className='fa fa-check'></i>
                            </span>
                            }
                        </div>
                        <div className={css.progressboxstyle+' '+css.wHIWv+checked0}></div>
                    </div>
                    <div className={css.kNirdD}>
                        <span className={css.fcCkVG}>
                            {OrderStatuses(0).name}
                        </span>
                    </div>
                </div>
                <div className={css.foKLRz}>
                    <div className={css.jYdTsb}>
                        <div className={css.jYdTsb+' '+css.flwWPp+checked1}>
                            {checked1==''?'2':
                            <span className={css.fRVGLf}>
                                <i className='fa fa-check'></i>
                            </span>
                            }
                        </div>
                        <div className={css.progressboxstyle+' '+css.wHIWv+checked1}></div>
                    </div>
                    <div className={css.kNirdD}>
                        <span className={css.fcCkVG}>
                            {OrderStatuses(1).name}
                        </span>
                    </div>
                </div>
                <div className={css.foKLRz}>
                    <div className={css.jYdTsb}>
                        <div className={css.jYdTsb+' '+css.flwWPp+checked2}>
                            {checked2==''?'3':
                            <span className={css.fRVGLf}>
                                <i className='fa fa-check'></i>
                            </span>
                            }
                        </div>
                        <div className={css.progressboxstyle+' '+css.wHIWv+checked2}></div>
                    </div>
                    <div className={css.kNirdD}>
                        <span className={css.fcCkVG}>
                            {OrderStatuses(2).name}
                        </span>
                    </div>
                </div>
                <div className={css.foKLRz}>
                    <div className={css.jYdTsb}>
                        <div className={css.jYdTsb+' '+css.flwWPp+checked3}>
                            {checked3==''?'0':
                            <span className={css.fRVGLf}>
                                <i className='fa fa-check'></i>
                            </span>
                            }
                        </div>
                        <div className={css.progressboxstyle+' '+css.wHIWv+checked3}></div>
                    </div>
                    <div className={css.kNirdD}>
                        <span className={css.fcCkVG}>
                            {OrderStatuses(3).name}
                        </span>
                    </div>
                </div>
            </div>
            <div className={css.rctable}>
                <table className={css.cAgIfa}>
                    <thead>
                        <tr>
                            <th>Бараа</th>
                            <th>Тоо ширхэг</th>
                            <th>Үнэ</th>
                        </tr>
                    </thead>
                    <tbody>
                        {inorderdetail.map((item,key)=>(
                            <tr key={'inorderdetail'+key} data-row-key={key+1}>
                                <td>
                                    <span className={css.lhXkXj}>
                                        
                                        <span className={css.iVTGVp}>
                                            <span className={css.kIgOkd}>{item.name}</span>
                                            {/* <span className={css.bnnPwi}>2lb</span> */}
                                            <span className={css.kcpNyZ}>
                                                {item.price}
                                            </span>
                                            {/* <span className={css.kcpNyZ}>
                                                {item.price1}₮
                                            </span> */}
                                            {/* <span className={css.kcpNyZ}>
                                                {item.price2}₮
                                            </span> */}
                                        </span>
                                    </span>
                                </td>
                                <td>{item.quantity}</td>
                                <td className={css.tdright}>
                                    <p>&nbsp;</p>
                                    <p>{item.price*item.quantity}</p>
                                    {/* <p>{item.price1*item.quantity}₮</p> */}
                                    {/* <p>{item.price2*item.quantity}₮</p> */}
                                </td>
                            </tr>
                        ))}
                        {/* <tr data-row-key="2">
                            <td>
                                <span className={css.lhXkXj}>
                                    <span className={css.kONoVC}>
                                        <img src='http://s3.amazonaws.com/redqteam.com/headless-image/banana.jpg'></img>
                                    </span>
                                    <span className={css.iVTGVp}>
                                        <span className={css.kIgOkd}>Banana</span>
                                        <span className={css.bnnPwi}>2lb</span>
                                        <span className={css.kcpNyZ}>
                                            {'$'}{'50'}
                                        </span>
                                    </span>
                                </span>
                            </td>
                            <td>2</td>
                            <td className={css.tdright}>
                                <p>{'$'}{'50'}</p>
                            </td>
                        </tr> */}
                    </tbody>
                </table>
            </div>
            </div>
            :<div className={css.notorder}>Захиалгын дэлгэрэнгүй олдсонгүй</div>}
        </div>
    )
}